import { makeStyles, Spinner, tokens } from '@fluentui/react-components'
import React from 'react'
const useStyles = makeStyles({
   container: {
      display: 'flex',
      boxShadow: tokens.shadow4,
      borderRadius: tokens.borderRadiusCircular,
      backgroundColor: tokens.colorPaletteMarigoldBackground1,
      paddingLeft: tokens.spacingHorizontalXL,
      paddingRight: tokens.spacingHorizontalXL,
      alignItems: 'center',
      height: '40px',
      minWidth: 'fit-content',
   },
   bold: {
      fontWeight: tokens.fontWeightBold,
   },
   lawsCount: {
      fontSize: tokens.fontSizeBase500,
      marginRight: tokens.spacingHorizontalXS,
   },
})
type LargeCounterBadgeProps = {
   number: number
   text: string
   loading: boolean
}
export const LargeCounterBadge = ({ number, text, loading }: LargeCounterBadgeProps) => {
   const classes = useStyles()
   return (
      <div className={classes.container}>
         <div className={`${classes.lawsCount} ${classes.bold}`}>{loading ? <Spinner size="extra-tiny" /> : number}</div>
         <div className={classes.lawsCount}>{text}</div>
      </div>
   )
}
