import SiteConstants from '../assets/SiteConstants'
import { Demand } from './schemas/schema'
import axios from 'axios'

export const AddDemand = async (demand: Demand): Promise<Demand> => {
   try {
      const data = await axios.post(`/${SiteConstants.DEMANDS}`, demand, {
         headers: {
            'Content-Type': 'application/json',
         },
      })
      return data.data
   } catch (error) {
      throw error
   }
}
export const AddDemands = async (demands: Demand[]): Promise<Demand[]> => {
   try {
      const data = await axios.post(`/${SiteConstants.DEMANDS}/batch`, demands, {
         headers: {
            'Content-Type': 'application/json',
         },
      })
      return data.data
   } catch (error) {
      throw error
   }
}

export const UpdateDemand = async (demand: Demand): Promise<Demand> => {
   try {
      const data = await axios.put(`/${SiteConstants.DEMANDS}`, demand, {
         headers: {
            'Content-Type': 'application/json',
         },
      })
      return data.data
   } catch (error) {
      throw error
   }
}

export const GetDemandById = async (id: string): Promise<Demand> => {
   try {
      const response = await axios.get(`/${SiteConstants.DEMANDS}/${id}`)
      const item: Demand = response.data

      return item
   } catch (error) {
      throw error
   }
}

export const DeleteDemand = async (id: string) => {
   try {
      await axios.delete(`/${SiteConstants.DEMANDS}/${id}`)
   } catch (error) {
      throw error
   }
}

export const GetDemands = async (): Promise<Demand[]> => {
   try {
      let url = `/${SiteConstants.DEMANDS}?`
      const response = await axios.get(url)
      const demands: Demand[] = response.data
      return demands
   } catch (error) {
      throw error
   }
}
