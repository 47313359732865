import { UseFormReturn, UseFormWatch } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'
import { QuestionFormValues } from './questionHelper'
import { Api } from '../api/schemas/schema'
import { IDataContext } from '../interfaces/IDataContext'
import { t } from '../i18n/i18n'

export const fileOnchange = (e: React.ChangeEvent<HTMLInputElement>, questionForm: UseFormReturn<QuestionFormValues>) => {
   const { setValue, watch } = questionForm
   const file = e.target.files[0]
   const reader = new FileReader()
   reader.onload = async (event) => {
      const arrayBuffer: any = event.target.result
      const base64String: any = btoa(new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), ''))
      setValue(
         'selectedQuestion.attachment',
         { bytes: base64String, name: file.name, attachmentId: uuidv4(), questionId: watch('selectedQuestion.id') },
         { shouldDirty: true }
      )
   }
   reader.readAsArrayBuffer(file)
}
export const getBlob = async (questionForm: UseFormReturn<QuestionFormValues>, apiInstance: Api<unknown>) => {
   const { setValue, watch } = questionForm
   if (!watch('selectedQuestion.attachment.bytes')) {
      const bytes: any = await apiInstance.api.fileblobDetail(watch('selectedQuestion.attachment.attachmentId'))
      setValue('selectedQuestion.attachment.bytes', bytes.data)
   }
   handleImagePreviewClick(watch)
}
export const handleImagePreviewClick = (watch: UseFormWatch<QuestionFormValues>) => {
   const fileName = watch('selectedQuestion.attachment.name')
   const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1)
   let url = ''
   if (fileExtension === 'pdf') {
      url = `<iframe src="data:application/pdf;base64,${encodeURI(watch('selectedQuestion.attachment.bytes'))}" width="100%" height="600px"></iframe>`
   } else {
      url = `<img src="data:image/png;base64,${watch('selectedQuestion.attachment.bytes')}" />`
   }

   const newWindow = window.open('', '_blank')
   if (newWindow) {
      newWindow.document.write(url)
      newWindow.document.close()
   }
}

export const deleteAttachment = async (
   questionForm: UseFormReturn<QuestionFormValues>,
   apiInstance: Api<unknown>,
   dataContext: IDataContext,
   closeDeleteAttachmentModal: () => void,
   inputFile: React.MutableRefObject<any>
) => {
   const { setValue, watch } = questionForm
   try {
      await apiInstance.api.fileblobDelete(watch('selectedQuestion.attachment.attachmentId'))
      setValue('selectedQuestion.attachment', null)
      inputFile.current.value = ''
      dataContext.handleMessage(dataContext.setRootState, 'success', t('ItemDeleted'), '')
      closeDeleteAttachmentModal()
   } catch (error: any) {
      dataContext.handleMessage(dataContext.setRootState, 'error', t('DeleteError'), error.message)
   }
}
