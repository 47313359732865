import { makeStyles, tokens, Text, Card } from '@fluentui/react-components'
import React, { useContext } from 'react'
import { levelBadgeColor } from '../../helpers/demandHelper'
import { Loader } from '../common/spinner/Loader'
import { Demand, DemandSearchResultDTO } from '../../api/schemas/schema'
import { DataContext } from '../../App'
import { Checkmark20Regular } from '@fluentui/react-icons'

const useStyles = makeStyles({
   ul: {
      listStyleType: 'none',
      paddingLeft: 0,
      margin: 0,
   },
   li: {
      display: 'flex',
      cursor: 'pointer',
      padding: tokens.spacingHorizontalM,
      alignItems: 'center',
   },
   liHover: {
      ':hover': {
         backgroundColor: tokens.colorNeutralBackground2Hover,
      },
   },
   liContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
   },
   brand: { backgroundColor: tokens.colorBrandBackground, color: tokens.colorNeutralBackground1Pressed },
   gold: { backgroundColor: tokens.colorPaletteGoldBackground2, color: tokens.colorNeutralForeground1 },
   searchBox: {
      boxSizing: 'border-box',
      width: '100%',
      maxWidth: '100%',
   },
   card: {
      maxWidth: '100%',
      maxHeight: '50vh',
      padding: 0,
      overflowY: 'auto',
   },
   iconContainer: {
      width: '30px',
   },
})
type DemandSearchResultsProps = {
   searchResult: DemandSearchResultDTO[]
   setTargetDemand: React.Dispatch<React.SetStateAction<DemandSearchResultDTO>>
   setSelected: React.Dispatch<React.SetStateAction<boolean>>
   targetDemand: Demand
}
export const DemandSearchResults = ({ searchResult, setTargetDemand, targetDemand, setSelected }: DemandSearchResultsProps) => {
   const classes = useStyles()
   const dataContext = useContext(DataContext)
   const { isLoading } = dataContext.state
   const demandIsSelected = (targetId: string, resultId: string) => {
      return targetId === resultId
   }
   return (
      <Card size="small" className={classes.card}>
         <ul className={classes.ul}>
            {searchResult.map((result) => (
               <li
                  key={result.id}
                  className={`${classes.li} ${
                     targetDemand && demandIsSelected(targetDemand?.id, result?.id) ? levelBadgeColor(targetDemand.level, classes) : classes.liHover
                  }`}
                  onClick={() => {
                     if (demandIsSelected(targetDemand?.id, result?.id)) {
                        setTargetDemand(null)
                        setSelected(false)
                     } else {
                        setTargetDemand(result)
                        setSelected(true)
                     }
                  }}
               >
                  <div className={classes.iconContainer}>{demandIsSelected(targetDemand?.id, result.id) ? <Checkmark20Regular /> : null}</div>
                  <div className={classes.liContainer}>
                     <div>
                        <Text as="p" weight="bold">
                           {result.designation}
                        </Text>
                        <br />
                        <Text>
                           {result.publicId} {result.urlText ? '- ' + result.urlText : ''}
                        </Text>
                     </div>
                     <div>{isLoading && demandIsSelected(targetDemand?.id, result?.id) && <Loader isCustom />}</div>
                  </div>
               </li>
            ))}
         </ul>
      </Card>
   )
}
