import { Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle } from '@fluentui/react-components'
import React from 'react'

type ModalProps = {
   open: boolean
   title: string
   children: string | JSX.Element | JSX.Element[]
   actions?: JSX.Element | JSX.Element[]
   contentClassname?: string
}
export const Modal = ({ open, title, children, actions, contentClassname }: ModalProps) => {
   return (
      <Dialog open={open}>
         <DialogSurface>
            <DialogBody>
               <DialogTitle>{title}</DialogTitle>
               <DialogContent className={contentClassname ?? ''}>{children}</DialogContent>
               {actions && <DialogActions>{actions}</DialogActions>}
            </DialogBody>
         </DialogSurface>
      </Dialog>
   )
}
