import React from 'react'
import { AnswerType, QuestionDTO, QuestionStatus } from '../../api/schemas/schema'
import { NavLink } from 'react-router-dom'
import StatusPill from '../../components/common/status/StatusPill'
import { statusBadgeColor } from '../../helpers/demandHelper'
import { enumValuesToTranslate } from '../../helpers/enumHelper'
import { TableCell, TableRow } from '@fluentui/react-components'
import { RTFOutput } from '../../components/common/outputs/RTFOutput'

type QuestionRowProps = {
   question: QuestionDTO
}

export const TableQuestionRow = ({ question }: QuestionRowProps) => {
   return (
      <TableRow>
         <TableCell>
            <NavLink style={{ textDecoration: 'none', color: 'black' }} to={`/demands/${question.parentId}?question=${question.order}`}>
               {question.designation}
            </NavLink>
         </TableCell>
         <TableCell>
            <NavLink style={{ textDecoration: 'none', color: 'black' }} to={`/demands/${question.parentId}?question=${question.order}`}>
               <RTFOutput text={question.text.length > 200 ? question.text.substring(0, 200) + '...' : question.text} fontSize={300} noMargin />
            </NavLink>
         </TableCell>
         <TableCell>{question.organisationSizes.map((x) => x.organisationSize.text).join(', ')}</TableCell>

         <TableCell>{question.organisationTypes.map((x) => x.organisationType.text).join(', ')}</TableCell>
         <TableCell>{question?.process?.process?.text && question?.process?.process?.text}</TableCell>
         <TableCell>{enumValuesToTranslate(AnswerType, question?.answerType)}</TableCell>
         <TableCell>
            <StatusPill color={statusBadgeColor(question.status)}>{enumValuesToTranslate(QuestionStatus, question.status)}</StatusPill>
         </TableCell>
      </TableRow>
   )
}
