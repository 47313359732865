import React, { useContext } from 'react'
import { Menu, MenuTrigger, Button, MenuPopover, MenuList, MenuItem } from '@fluentui/react-components'
import { t } from '../../i18n/i18n'
import { Demand, DemandStatus } from '../../api/schemas/schema'
import { SelectedDemandModal, addDemand, getNewDemand, isDemandFormDisabled } from '../../helpers/demandHelper'
import { Archive16Regular, BookAdd20Regular, ChevronDown16Regular, Delete16Regular } from '@fluentui/react-icons'
import { DataContext } from '../../App'
import { useNavigate } from 'react-router-dom'
import { UseFormReturn } from 'react-hook-form'
import { useMsal } from '@azure/msal-react'

type SelectedDemandActionsProps = {
   demandForm: UseFormReturn<Demand, any, undefined>
   setOpenModal: React.Dispatch<React.SetStateAction<SelectedDemandModal>>
   setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>
   deleteBtnDisabled: boolean
   grandParentId: string
}
export const SelectedDemandActions = ({ demandForm, setOpenModal, setIsSubmitting, deleteBtnDisabled, grandParentId }: SelectedDemandActionsProps) => {
   const dataContext = useContext(DataContext)
   const navigate = useNavigate()
   const { accounts } = useMsal()
   const { watch } = demandForm
   const isDisabled = isDemandFormDisabled(watch())
   return (
      <>
         <Button
            onClick={async () => await addDemand(getNewDemand(watch(), accounts[0].name), grandParentId, dataContext, setIsSubmitting, navigate)}
            icon={<BookAdd20Regular />}
            appearance="transparent"
            disabled={isDisabled || watch('level') === 3}
         >
            {t('NewSubDemand')}
         </Button>

         {watch('level') !== 1 && (
            <Menu positioning="below-end">
               <MenuTrigger disableButtonEnhancement>
                  <Button appearance="transparent" icon={<ChevronDown16Regular />} />
               </MenuTrigger>

               <MenuPopover>
                  <MenuList>
                     <>
                        <MenuItem icon={<Archive16Regular />} onClick={() => setOpenModal(SelectedDemandModal.ARCHIVE)}>
                           {watch('status') === DemandStatus.Archived ? t('Activate') : t('Archive')}
                        </MenuItem>
                        <MenuItem icon={<Delete16Regular />} onClick={() => setOpenModal(SelectedDemandModal.DELETE)} disabled={deleteBtnDisabled}>
                           {t('Delete')}
                        </MenuItem>
                     </>
                  </MenuList>
               </MenuPopover>
            </Menu>
         )}
      </>
   )
}
