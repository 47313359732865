import React from 'react'
import { IExtendedColumn } from '../../interfaces/IColumn'
import { enumValuesToTranslate } from '../../helpers/enumHelper'
import { Demand, QuestionDTO, QuestionStatus } from '../../api/schemas/schema'
import { NavLink } from 'react-router-dom'
import StatusPill from '../../components/common/status/StatusPill'
import { statusBadgeColor } from '../../helpers/demandHelper'
import { makeStyles, mergeClasses, tokens } from '@fluentui/react-components'
import { t } from '../../i18n/i18n'
import { RTFOutput } from '../../components/common/outputs/RTFOutput'
import { Text } from '../../components/common/texts/Text'
type ReorderQuestionRowProps = {
   columns: IExtendedColumn[]
   rowClass: string
   demand: Demand
   question: QuestionDTO
}

const useStyles = makeStyles({
   customTableRow: {
      backgroundColor: tokens.colorPaletteMarigoldBackground1,
      '&:hover': {
         backgroundColor: tokens.colorPaletteMarigoldBackground2,
      },
   },
   questionLabel: {
      display: 'flex',
      justifyContent: 'center',
   },
   label: {
      fontWeight: tokens.fontWeightBold,
   },
   spacingHorizontalMNudge: {
      marginRight: tokens.spacingHorizontalMNudge,
   },
   spacingHorizontalXXXL: {
      marginRight: tokens.spacingHorizontalXXXL,
   },
})

export const CustomQuestionRow = ({ rowClass, columns, demand, question }: ReorderQuestionRowProps) => {
   const classes = useStyles()

   const calculateQuestionTextWidth = () => {
      let width = 0
      for (let index = 4; index < 9; index++) {
         const widthValue = columns[index].width
         width += parseInt(widthValue.replace('px', ''), 10)
      }
      return `${width}%`
   }

   return (
      <div className={mergeClasses(rowClass, classes.customTableRow)}>
         <span style={{ width: columns[0].width, minWidth: columns[0].minWidth }}></span>
         <span style={{ width: columns[1].width, minWidth: columns[1].minWidth }}>
            <div
               className={`${classes.questionLabel} ${
                  demand.level === 2 ? classes.spacingHorizontalMNudge : demand.level === 1 ? classes.spacingHorizontalXXXL : null
               }`}
            >
               <text className={classes.label}>{t('Question')}</text>
            </div>
         </span>
         <span style={{ width: columns[2].width, minWidth: columns[2].minWidth }}>
            <Text>{`${demand.publicId}.${question.publicId}`}</Text>
         </span>
         <span style={{ width: columns[3].width, minWidth: columns[3].minWidth }}>{question.designation}</span>
         <span style={{ width: calculateQuestionTextWidth() }}>
            <NavLink style={{ textDecoration: 'none', color: 'black' }} to={`/demands/${demand.id}?question=${question.order}`}>
               <RTFOutput text={question.text.length > 300 ? question.text.substring(0, 300) + '...' : question.text} fontSize={300} noMargin />
            </NavLink>
         </span>
         <span style={{ width: columns[9].width, minWidth: columns[9].minWidth }}>
            <StatusPill color={statusBadgeColor(question.status)}>{enumValuesToTranslate(QuestionStatus, question.status)}</StatusPill>
         </span>
         <span style={{ width: columns[10].width, minWidth: columns[10].minWidth }} />
      </div>
   )
}
