import React, { useContext, useEffect, useState } from 'react'
import { Tab, TabList, makeStyles, mergeClasses, shorthands, tokens, Text, Image } from '@fluentui/react-components'
import { DataContext } from '../../../App'
import i18n, { t } from '../../../i18n/i18n'
import { useNavigate } from 'react-router'
import {
   Home24Regular,
   BookDatabase24Regular,
   Gavel24Regular,
   TableSettings24Regular,
   Home24Filled,
   BookDatabase24Filled,
   Gavel24Filled,
   TableSettings24Filled,
} from '@fluentui/react-icons'
const useStyles = makeStyles({
   nav: {
      position: 'fixed',
      top: '60px',
      width: '60px',
      boxShadow: tokens.shadow8,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: tokens.colorNeutralBackground1,
      zIndex: 2,
      height: 'calc(100vh - 60px)',
      justifyContent: 'space-between',
   },
   expanded: {
      width: '316px',
      backgroundColor: tokens.colorNeutralBackground1,
   },
   smallNavBtnWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '50px',
      width: '64px',
   },
   tabItem: {
      ...shorthands.padding(tokens.spacingHorizontalM, tokens.spacingHorizontalM, tokens.spacingHorizontalM, tokens.spacingHorizontalXL),
      '::after': {
         content: '""', // Note the nested quotes
         width: '8px',
         ...shorthands.borderRadius(0),
      },
   },
   hideInPhone: {
      display: 'none',
      '@media screen and (min-width: 600px)': { display: 'block' },
   },
   chrevron: {
      paddingLeft: tokens.spacingHorizontalXL,
   },

   tabList: {
      width: '100%',

      '& button': {
         paddingLeft: '18px',
      },
      '@media screen and (max-width: 1007px)': {
         justifyContent: 'space-around',
         '& button': {
            paddingLeft: '10px',
         },
      },
   },
   flagContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      gap: tokens.spacingVerticalM,
      padding: `0 ${tokens.spacingHorizontalS}`,
   },
   img: {
      cursor: 'pointer',
      marginLeft: tokens.spacingVerticalXS,
   },
})
export const Navigation = () => {
   const classes = useStyles()
   const data = useContext(DataContext)

   const { navigationExpanded } = data.state
   const navigate = useNavigate()
   const packageJson = require('../../../../package.json')
   const appVersion = packageJson.version
   const { pathname } = window.location
   const [selectedTab, setSelectedTab] = useState<string>(window.location.pathname)
   useEffect(() => {
      setSelectedTab(`/${window.location.pathname.split('/')[1]}`)
   }, [])
   return (
      <>
         <aside className={mergeClasses(classes.nav, navigationExpanded ? classes.expanded : '')}>
            <TabList vertical selectedValue={selectedTab} onTabSelect={(e, data) => navigate(data.value)} size="large" className={classes.tabList}>
               <Tab icon={pathname === '/' ? <Home24Filled /> : <Home24Regular />} value={'/'}>
                  {navigationExpanded && t('Home')}
               </Tab>
               {/* <Tab icon={pathname === '/customer-register' ? <People24Filled /> : <People24Regular />} value={'/customer-register'}>
                  {navigationExpanded && t('CustomerRegister')}
               </Tab> */}
               <Tab icon={pathname.includes('/demands') ? <BookDatabase24Filled /> : <BookDatabase24Regular />} value={'/demands'}>
                  {navigationExpanded && t('DemandQuestionList')}
               </Tab>
               <Tab icon={pathname.includes('/law-import') ? <Gavel24Filled /> : <Gavel24Regular />} value={'/law-import'}>
                  {navigationExpanded && t('LawsImport')}
               </Tab>
               {/* <Tab icon={pathname === '/knowledge-bank' ? <HatGraduation24Filled /> : <HatGraduation24Regular />} value={'/knowledge-bank'}>
                  {navigationExpanded && t('KnowledgeBankFormCustomer')}
               </Tab> */}
               <Tab icon={pathname === '/system-settings' ? <TableSettings24Filled /> : <TableSettings24Regular />} value={'/system-settings'}>
                  {navigationExpanded && t('SystemSettings')}
               </Tab>
            </TabList>

            <div className={classes.flagContainer}>
               {window.location.host.includes('localhost') || window.location.host.includes('test') ? (
                  i18n.language === 'sv' ? (
                     <Image
                        shape="rounded"
                        bordered
                        className={classes.img}
                        width={35}
                        alt={'Swedish'}
                        src="/flags/sweden.svg"
                        onClick={() => {
                           i18n.changeLanguage('en')
                           window.location.reload()
                        }}
                     />
                  ) : (
                     <Image
                        shape="rounded"
                        bordered
                        className={classes.img}
                        width={35}
                        alt={'English'}
                        src="/flags/england.svg"
                        onClick={() => {
                           i18n.changeLanguage('sv')
                           window.location.reload()
                        }}
                     />
                  )
               ) : null}

               <Text block size={200} align="center">
                  v {appVersion}
               </Text>
            </div>
         </aside>
      </>
   )
}
