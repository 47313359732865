export const saveScrollPosition = () => {
   localStorage.setItem('scrollPosition', window.scrollY.toString())
}

export const clearScrollPosition = () => {
   localStorage.removeItem('scrollPosition')
   window.scrollTo(0, 0)
}

export const scrollToSavedPosition = () => {
   const savedScrollPosition = localStorage.getItem('scrollPosition')
   if (savedScrollPosition) {
      window.scrollTo(0, parseInt(savedScrollPosition, 10))
   }
}
