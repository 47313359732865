import { DemandRow } from './DemandRow'
import { Demand } from '../../api/schemas/schema'
import { motion } from 'framer-motion'
type DemandsProps = {
   demands: Demand[]
   loadingRows: string[]
   showButton: string
   expandAllDemands: boolean
   setShowButton: (value: React.SetStateAction<string>) => void
   toggleRowExpansion: (demandId: string) => void
}
export const Demands = ({ demands, loadingRows, showButton, expandAllDemands, setShowButton, toggleRowExpansion }: DemandsProps): JSX.Element => {
   return (
      <motion.div layout>
         {demands.map((demand) => (
            <DemandRow
               demands={demands}
               demand={demand}
               loadingRows={loadingRows}
               showButton={showButton}
               expandAllDemands={expandAllDemands}
               setShowButton={setShowButton}
               toggleRowExpansion={toggleRowExpansion}
            />
         ))}
      </motion.div>
   )
}
