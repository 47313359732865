import React, { useContext, useEffect, useState } from 'react'
import { Grid } from '../../components/common/grid/Grid'
import { GridItem } from '../../components/common/grid/GridItem'
import { ControlledRTFInput } from '../../components/common/inputs/ControlledRTFInput'
import { ControlledDropDown } from '../../components/common/inputs/Dropdown'
import { ControlledTextInput } from '../../components/common/inputs/TextInput'
import { enumValuesToOptions } from '../../helpers/enumHelper'
import { TextOutput } from '../../components/common/outputs/TextOutput'
import { Button, Field, makeStyles, tokens } from '@fluentui/react-components'
import { DemandArea, Demand, DemandStatus, DemandSubType, DemandType, Origin, DemandSearchResultDTO } from '../../api/schemas/schema'
import { UseFormReturn } from 'react-hook-form'
import { DataContext } from '../../App'
import { SelectedDemandModal, updateDemand } from '../../helpers/demandHelper'
import { useMsal } from '@azure/msal-react'
import { SubDemands } from '../../components/demand/SubDemands'
import dayjs from 'dayjs'
import { getDemandLeveLOptions } from '../../helpers/optionsHelper'
import { ParentDemandModal } from '../../components/demand/ParentDemandModal'
import { Modal } from '../../components/common/modal/Modal'
import { DemandSearchBox } from '../../components/demand/DemandSearchBox'
import PersonaOutput from '../../components/common/outputs/PersonaOutput'
import { t } from '../../i18n/i18n'

const useStyles = makeStyles({
   marginRight: {
      marginRight: tokens.spacingHorizontalXL,
   },
   smallGap: {
      display: 'flex',
      flexDirection: 'column',
      gap: tokens.spacingVerticalXL,
   },
   flexEnd: { display: 'flex', justifyContent: 'flex-end', alignItems: 'center' },
   flexBtw: { display: 'flex', justifyContent: 'space-between', paddingRight: tokens.spacingHorizontalXXXL },

   flex: { display: 'flex', gap: tokens.spacingHorizontalS },
   resetLink: {
      color: tokens.colorNeutralForeground1,
   },
   semibold: {
      fontWeight: tokens.fontWeightSemibold,
   },
   editor: {
      minHeight: '294px',
   },
   grid: {
      paddingTop: tokens.spacingVerticalXXL,
   },
})

type DemandFormProps = {
   demandForm: UseFormReturn<Demand, any, undefined>
   setOriginDemand: React.Dispatch<React.SetStateAction<Demand>>
   setOpenModal: React.Dispatch<React.SetStateAction<SelectedDemandModal>>
   setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>
   setBread: (parentId: string) => Promise<void>
   originDemand: Demand
   openModal: SelectedDemandModal
   isDisabled: boolean
   isSubmitting: boolean
}
export const DemandForm = ({
   demandForm,
   setOriginDemand,
   setOpenModal,
   setIsSubmitting,
   setBread,
   originDemand,
   openModal,
   isDisabled,
   isSubmitting,
}: DemandFormProps) => {
   const dataContext = useContext(DataContext)
   const { accounts } = useMsal()
   const { setValue, watch, reset, control } = demandForm
   const { isDirty } = demandForm.formState
   const classes = useStyles()
   const [targetParentDemand, setTargetParentDemand] = useState<DemandSearchResultDTO>(null)
   const onSaveClick = async () => {
      setIsSubmitting(true)
      setValue('modifiedBy', accounts[0].name)
      await updateDemand(watch(), dataContext, reset, setIsSubmitting, setOriginDemand, setBread)
      setTargetParentDemand(null)
   }
   useEffect(() => {
      if (watch('level') !== originDemand.level) {
         setOpenModal(SelectedDemandModal.PARENT)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [watch('level')])
   useEffect(() => {
      if (targetParentDemand) setValue('parentId', targetParentDemand.id, { shouldDirty: true })
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [targetParentDemand?.id])
   const onUndoClick = () => {
      reset(originDemand)
      setOpenModal(null)
      setTargetParentDemand(null)
   }
   return (
      <>
         <Grid className={classes.grid}>
            <GridItem size="5/12">
               <ControlledRTFInput
                  name={'text'}
                  control={control}
                  label={t('Demand')}
                  isDisabled={isDisabled || isSubmitting}
                  required={true}
                  editorClassName={classes.editor}
                  watchedValue={watch('text')}
               />
            </GridItem>
            <GridItem className={classes.smallGap} size="2/12">
               <ControlledDropDown
                  control={control}
                  name={'status'}
                  options={enumValuesToOptions(DemandStatus)
                     .filter((x) => x.key !== DemandStatus.SavedButNotSure && (watch('status') === DemandStatus.Archived || x.key !== DemandStatus.Archived))
                     .map((option) => ({
                        ...option,
                     }))}
                  label={t('Status')}
                  disabled={isDisabled || isSubmitting}
               />
               <ControlledDropDown
                  control={control}
                  name={'area'}
                  options={enumValuesToOptions(DemandArea)}
                  label={t('DemandArea')}
                  disabled={watch('level') !== 2 || isDisabled || isSubmitting}
               />
               <ControlledDropDown
                  control={control}
                  name={'type'}
                  options={enumValuesToOptions(DemandType).map((x) => (x.key === DemandType.InsuranceDemand ? { ...x, disabled: true } : x))}
                  label={t('DemandType')}
                  disabled={watch('level') !== 2 || isDisabled || isSubmitting}
               />
               <ControlledDropDown
                  control={control}
                  name={'subType'}
                  options={enumValuesToOptions(DemandSubType)}
                  label={t('SubType')}
                  disabled={isDisabled || isSubmitting}
               />
               <ControlledTextInput control={control} name={'version'} label={t('Version')} disabled={isDisabled || isSubmitting} />
            </GridItem>
            <GridItem className={classes.smallGap} size="4/12">
               <ControlledTextInput control={control} name={'source'} label={t('DemandSource')} disabled={isDisabled || isSubmitting} />
               <ControlledTextInput control={control} name={'urlText'} label={t('UrlText')} disabled={isDisabled || isSubmitting} />
               <ControlledTextInput control={control} name={'url'} label={t('WebLink')} disabled={isDisabled || isSubmitting} />
               <ControlledDropDown
                  control={control}
                  name={'origin'}
                  options={enumValuesToOptions(Origin)}
                  label={t('Origin')}
                  disabled={isDisabled || isSubmitting}
               />
            </GridItem>
            <GridItem className={classes.smallGap} size="1/12">
               <ControlledDropDown
                  control={control}
                  name={'level'}
                  label={t('DemandLevel')}
                  disabled={originDemand.level === 1 || (originDemand.level === 2 && originDemand.children.length > 0) || isDisabled || isSubmitting}
                  options={getDemandLeveLOptions(originDemand.level)}
               />
               {watch('level') === 3 && (
                  <DemandSearchBox
                     targetDemand={targetParentDemand ? targetParentDemand : originDemand.parent}
                     setTargetDemand={setTargetParentDemand}
                     placeholder={t('Search') + '...'}
                     targetDemandLevel={watch('level') - 1}
                     filterOutDemand={watch()}
                     label={t('ParentDemand')}
                     required
                     hideSearchIcon
                     isDisabled={isDisabled || isSubmitting}
                  />
               )}
               {originDemand.parentId !== watch('parentId') && watch('level') === 2 && (
                  <Field label={t('NewParentDemand')}>{targetParentDemand.designation}</Field>
               )}
               {watch('level') !== 3 && <SubDemands originDemand={originDemand} />}
            </GridItem>

            <GridItem size="5/12" className={classes.flexBtw}>
               <TextOutput value={watch('publicId').toString()} label={t('ID')} />
               <TextOutput label={t('Modified')} value={dayjs(watch('modifiedDate')).format('YYYY-MM-DD HH:mm')} />
               <PersonaOutput value={watch('modifiedBy')} label={t('ModifiedBy')} />
            </GridItem>
            <GridItem size="7/12" className={classes.flexEnd}>
               <Button className={classes.marginRight} disabled={!isDirty || isSubmitting} onClick={() => setOpenModal(SelectedDemandModal.UNDO)}>
                  {t('Undo')}
               </Button>
               <Button appearance="primary" onClick={async () => onSaveClick()} disabled={!isDirty || isSubmitting}>
                  {t('Save')}
               </Button>
            </GridItem>
         </Grid>
         <Modal
            open={openModal === SelectedDemandModal.UNDO}
            title={t('UndoChanges')}
            actions={
               <>
                  <Button onClick={() => setOpenModal(null)}>{t('Cancel')}</Button>
                  <Button onClick={() => onUndoClick()} appearance="primary">
                     {t('Continue')}
                  </Button>
               </>
            }
            children={t('UndoDemandChanges')}
         />
         <ParentDemandModal
            targetDemandLevel={watch('level') - 1}
            demandForm={demandForm}
            setOpenModal={setOpenModal}
            openModal={openModal}
            setTargetDemand={setTargetParentDemand}
            targetDemand={targetParentDemand}
         />
      </>
   )
}
