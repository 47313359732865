import { UseFormSetValue } from 'react-hook-form'
import { SNI, SNIQuestionConnection, SNIVersion } from '../api/schemas/schema'
import { QuestionFormValues } from './questionHelper'

export const getRelevantSnis = (items: SNIQuestionConnection[], sniVersion: SNIVersion): SNIQuestionConnection[] => {
   return items.filter((x) => x.sni.version === sniVersion)
}
export const getFilteredOutSnis = (items: SNIQuestionConnection[], sniVersion: SNIVersion): SNIQuestionConnection[] => {
   return items.filter((x) => x.sni.version !== sniVersion)
}

export const groupClick = (
   setValue: UseFormSetValue<QuestionFormValues>,
   allSelectedSnis: SNIQuestionConnection[],
   items: SNI[],
   sniVersion: SNIVersion,
   department: string,
   questionId: string
) => {
   const departmentLetter = department.charAt(0)

   const valuesToKeepSelected = getFilteredOutSnis(allSelectedSnis, sniVersion)
   const filteredCurrentSelectedSnis = getRelevantSnis(allSelectedSnis, sniVersion)
   const departmentItems = items.filter((x) => x.department.charAt(0) === departmentLetter)

   let newSelectedSnIs = new Set(filteredCurrentSelectedSnis.map((sn) => sn.sniId))
   departmentItems.forEach((option) => {
      newSelectedSnIs.add(option.id)
   })
   if (filteredCurrentSelectedSnis.filter((x) => x.sni && x.sni.department.charAt(0) === departmentLetter).length === departmentItems.length) {
      setSNIValue(
         setValue,
         valuesToKeepSelected,
         filteredCurrentSelectedSnis.filter((x) => x.sni && x.sni.department.charAt(0) !== departmentLetter)
      )
   } else {
      setSNIValue(
         setValue,
         valuesToKeepSelected,
         Array.from(newSelectedSnIs)
            .map((sniId) => ({
               sniId: sniId,
               questionId: questionId,
               sni: items.find((item) => item.id === sniId),
            }))
            .filter((x) => x.sni)
      )
   }
}
export const allClick = (
   setValue: UseFormSetValue<QuestionFormValues>,
   allSelectedSnis: SNIQuestionConnection[],
   items: SNI[],
   sniVersion: SNIVersion,
   questionId: string
) => {
   const currentSelectedSnis = allSelectedSnis
   const allSelectedSniIds = new Set(currentSelectedSnis.filter((x) => x.sni).map((sn) => sn.sniId))
   const allSNIsSelected = getRelevantSnis(allSelectedSnis, sniVersion).length === items.length

   if (allSNIsSelected) {
      setSNIValue(setValue, getFilteredOutSnis(allSelectedSnis, sniVersion), [])
   } else {
      const newSnis = items
         .filter((item) => !allSelectedSniIds.has(item.id))
         .map((item) => ({
            sniId: item.id,
            questionId: questionId,
            sni: item,
         }))

      setSNIValue(
         setValue,
         currentSelectedSnis.filter((x) => x.sni),
         newSnis
      )
   }
}

export const getSelectedGroups = (allSelectedSnis: SNIQuestionConnection[], items: SNI[], uniqueGroups: string[], sniVersion: SNIVersion) => {
   const selectedSnis = getRelevantSnis(allSelectedSnis, sniVersion)
   const selectedGroups: string[] = []
   uniqueGroups.forEach((g) => {
      if (selectedSnis.filter((x) => x.sni.department === g).length === items.filter((item) => item.department === g).length) {
         selectedGroups.push(g)
      }
   })
   if (selectedGroups.length === uniqueGroups.length) selectedGroups.push('Alla')
   return selectedGroups
}

export const setSNIValue = (
   setValue: UseFormSetValue<QuestionFormValues>,
   valuesToKeepSelected: SNIQuestionConnection[],
   newValues: SNIQuestionConnection[]
) => {
   setValue('selectedQuestion.snIs', [...valuesToKeepSelected, ...newValues], { shouldDirty: true })
}
