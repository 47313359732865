import React, { useContext, useEffect, useState } from 'react'
import { Grid } from '../components/common/grid/Grid'
import { useForm } from 'react-hook-form'
import { Demand } from '../api/schemas/schema'
import { t } from '../i18n/i18n'
import { Page } from './Page'
import { useParams } from 'react-router-dom'
import { IBreadcrumb } from '../interfaces/IBreadcrumb'
import { BasicBreadcrumb } from '../components/common/breadcrumb/BasicBreadcrumb'
import { SelectedQuestion } from '../components/question/SelectedQuestion'
import { SelectedDemand } from './Demand/SelectedDemand'
import { GetDemandById } from '../api/demand'
import { handleMessage } from '../helpers/stateHelper'
import { Loader } from '../components/common/spinner/Loader'
import { DataContext } from '../App'
import { QuestionFormValues } from '../helpers/questionHelper'

export const DemandQuestion = () => {
   const { id } = useParams()
   const dataContext = useContext(DataContext)

   const demandForm = useForm<Demand>()
   const [originDemand, setOriginDemand] = useState<Demand>()
   const [loading, setLoading] = useState<boolean>(true)
   const [grandParentId, setGrandParentId] = useState<string>()
   const { watch, reset } = demandForm
   const questionForm = useForm<QuestionFormValues>()
   useEffect(() => {
      if (id) {
         ;(async () => {
            try {
               const demand: Demand = await GetDemandById(id)
               reset(demand)
               setOriginDemand(demand)
               await setBread(demand.parentId)
            } catch (error) {
               handleMessage(dataContext.setRootState, 'error', t('CouldNotFetchDemand'), '')
            } finally {
               setLoading(false)
            }
         })()
      }
      //eslint-disable-next-line react-hooks/exhaustive-deps
   }, [id])

   const [breadcrumbItems, setBreadcrumbItems] = useState<IBreadcrumb[]>()
   const setBread = async (parentDemandId: string) => {
      let breadcrumbs: IBreadcrumb[] = []

      const updateBreadcrumbs = async (id: string) => {
         const parent = await GetDemandById(id)
         breadcrumbs.push({
            path: `/demands/${parent.id}`,
            text: `${t('Demand')} - ${parent.designation}`,
         })
         if (parent.level !== 1) await updateBreadcrumbs(parent.parentId)
         else setGrandParentId(parent.id)
         if (parent.id === parentDemandId) setOriginDemand((prev) => ({ ...prev, parent }))
      }

      let currentId = id
      await updateBreadcrumbs(currentId)

      breadcrumbs.push({ text: t('DemandQuestionList'), path: '/demands' })
      breadcrumbs.reverse()
      setBreadcrumbItems(breadcrumbs)
   }

   return (
      <>
         <Page header={<BasicBreadcrumb breadcrumbItems={breadcrumbItems} />}>
            {loading ? (
               <Loader />
            ) : (
               <Grid>
                  <SelectedDemand
                     setOriginDemand={setOriginDemand}
                     originDemand={originDemand}
                     demandForm={demandForm}
                     setBread={setBread}
                     hasQuestions={questionForm.watch('questions') && questionForm.watch('questions').length > 0}
                     grandParentId={grandParentId}
                  />
                  <SelectedQuestion questionForm={questionForm} demand={watch()} grandParentId={grandParentId} />
               </Grid>
            )}
         </Page>
      </>
   )
}
