import React, { useContext, useEffect, useId } from 'react'
import {
   tokens,
   shorthands,
   makeStyles,
   Toast,
   Toaster,
   useToastController,
   ToastBody,
   ToastTitle,
   ToastIntent,
   mergeClasses,
} from '@fluentui/react-components'
import { Header } from '../components/common/header/Header'
import { Navigation } from '../components/common/navigation/Navigation'
import { DataContext } from '../App'
import { Loader } from '../components/common/spinner/Loader'
type PageProps = {
   children: JSX.Element[] | JSX.Element | string | string[]
   header?: JSX.Element
   subheader?: JSX.Element
   stickySubHeader?: boolean
}

const useStyles = makeStyles({
   page: {
      minHeight: '100vh',
   },
   main: {
      paddingBottom: '80px',
      paddingTop: '60px',
      minHeight: 'calc(100vh - 60px)',
      backgroundColor: tokens.colorNeutralBackground4,
   },
   mainPaddingLeft: {
      '@media screen and (min-width: 600px)': { paddingLeft: '60px' },
   },
   header: {
      display: 'flex',
      alignItems: 'center',
      position: 'sticky',
      top: '60px',
      zIndex: 2,
      width: '100%',
      backgroundColor: tokens.colorNeutralBackground1,
      boxSizing: 'border-box',
      boxShadow: tokens.shadow8,
      marginBottom: tokens.spacingVerticalXL,
      height: '50px',
   },
   sticky: {
      position: 'sticky',
      top: '110px',
      zIndex: 1,
      backgroundColor: tokens.colorNeutralBackground4,
   },
   mainPadding: { ...shorthands.padding(0, tokens.spacingHorizontalXXL) },
})

export const Page = ({ children, header, subheader, stickySubHeader }: PageProps) => {
   const classes = useStyles()
   const toasterId = useId()
   const { dispatchToast } = useToastController(toasterId)
   const dataContext = useContext(DataContext)
   const { message, initialLoading } = dataContext.state

   const notify = (intent: ToastIntent, title: string, text: string) => {
      dispatchToast(
         <Toast>
            <ToastTitle>{title}</ToastTitle>
            <ToastBody>{text}</ToastBody>
         </Toast>,
         { intent }
      )
   }
   useEffect(() => {
      if (message) {
         notify(message.intent, message.title, message.text)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [message])
   return (
      <div className={classes.page}>
         <Header />

         <div className={classes.mainPaddingLeft}>
            <main className={classes.main}>
               {initialLoading ? (
                  <Loader />
               ) : (
                  <>
                     {header && (
                        <>
                           <header className={mergeClasses(classes.header, classes.mainPadding)}>{header}</header>
                           <div className={`${classes.mainPadding} ${stickySubHeader && classes.sticky}`}>{subheader}</div>
                        </>
                     )}
                     <div className={classes.mainPadding}>{children}</div>
                  </>
               )}
            </main>
         </div>
         <Navigation />
         <Toaster pauseOnHover position="top-end" toasterId={toasterId} />
      </div>
   )
}
