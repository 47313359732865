import React from 'react'
import { Button, makeStyles, mergeClasses, MessageBar, MessageBarBody, MessageBarIntent, MessageBarTitle, tokens } from '@fluentui/react-components'
import { t } from '../../../i18n/i18n'
import { ILanguage } from '../../../interfaces/ILanguage'
const useStyles = makeStyles({
   marginB: {
      marginBottom: tokens.spacingVerticalL,
   },
   marginR: {
      marginRight: tokens.spacingHorizontalM,
   },
   buttonMargin: {
      marginLeft: tokens.spacingHorizontalXXL,
   },
   padding: {
      padding: '6px',
   },
})
type UserMessageProps = {
   title: keyof ILanguage
   message: keyof ILanguage
   type?: MessageBarIntent
   reviewDemands?: boolean
   onDemandReview?: () => void
}
export const UserMessage = ({ title, message, type, reviewDemands, onDemandReview }: UserMessageProps): JSX.Element => {
   const classes = useStyles()
   return (
      <MessageBar intent={type} className={mergeClasses(classes.marginB, reviewDemands && classes.padding)}>
         <MessageBarBody>
            <MessageBarTitle className={classes.marginR}>{t(title)}</MessageBarTitle>
            {t(message)}
            {reviewDemands && (
               <Button className={classes.buttonMargin} onClick={() => onDemandReview()}>
                  {t('DemandsHaveBeenReviwed')}
               </Button>
            )}
         </MessageBarBody>
      </MessageBar>
   )
}
