import React, { useContext, useEffect, useState } from 'react'
import { Menu, MenuTrigger, Button, MenuPopover, MenuList, MenuItem } from '@fluentui/react-components'
import { MoreHorizontal20Regular, ArrowSort20Regular } from '@fluentui/react-icons'
import { t } from '../../i18n/i18n'
import { Demand } from '../../api/schemas/schema'
import { DataContext } from '../../App'
import { DemandDNDModal } from './DemandDNDModal'

type ContextualDemandMenuProps = {
   parent: Demand
}
export const ContextualDemandMenu = ({ parent }: ContextualDemandMenuProps) => {
   const dataContext = useContext(DataContext)
   const { demands } = dataContext.state
   const [reorderModalOpen, setReorderModalOpen] = useState<boolean>(false)
   const [reorderDemands, setReorderDemands] = useState<Demand[]>([])
   useEffect(() => {
      setReorderDemands(parent ? parent.children : demands)
   }, [reorderModalOpen, parent, demands])

   if (reorderDemands.length < 2) return null
   return (
      <>
         <Menu>
            <MenuTrigger>
               <Button icon={<MoreHorizontal20Regular />} appearance="transparent" />
            </MenuTrigger>
            <MenuPopover>
               <MenuList>
                  <MenuItem onClick={() => setReorderModalOpen(true)} icon={<ArrowSort20Regular />}>
                     {t('ChangeOrder')}
                  </MenuItem>
               </MenuList>
            </MenuPopover>
         </Menu>
         <DemandDNDModal setReorderDemands={setReorderDemands} setOpen={setReorderModalOpen} reorderDemands={reorderDemands} open={reorderModalOpen} />
      </>
   )
}
