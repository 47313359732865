import { Spinner, makeStyles, mergeClasses } from '@fluentui/react-components'
import React from 'react'
const useStyles = makeStyles({
   spinner: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 100,
   },
   transparent: { backgroundColor: 'white', opacity: 0.4 },
})
type LoaderProps = {
   transparent?: boolean
   isCustom?: boolean
}
export const Loader = ({ transparent, isCustom }: LoaderProps): JSX.Element => {
   const classes = useStyles()
   return <Spinner className={mergeClasses(!isCustom ? classes.spinner : '', transparent ? classes.transparent : '')} />
}
