import React from 'react'
import { tokens, shorthands, makeStyles } from '@fluentui/react-components'
import { t } from '../../i18n/i18n'
import { Law } from '../../api/schemas/schema'
import { Grid } from '../common/grid/Grid'
import { GridItem } from '../common/grid/GridItem'
import { BasicBreadcrumb } from '../common/breadcrumb/BasicBreadcrumb'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles({
   headerContent: {
      ...shorthands.padding(tokens.spacingVerticalS, 0),
   },
})
type SelectedLawHeaderProps = {
   selectedLaw: Law
}
const SelectedLawHeader = ({ selectedLaw }: SelectedLawHeaderProps) => {
   const classes = useStyles()
   const { id } = useParams()

   return (
      <Grid className={classes.headerContent}>
         <GridItem size="8/12">
            <BasicBreadcrumb
               breadcrumbItems={[
                  { text: t('LawsImport'), path: '/law-import?page=1' },
                  { text: selectedLaw?.sfsNr, path: `/law-import/${id}` },
               ]}
            />
         </GridItem>
      </Grid>
   )
}

export default SelectedLawHeader
