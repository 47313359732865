import React from 'react'
import { Card, CardHeader, makeStyles, tokens, InfoLabel, mergeClasses, Caption1, Subtitle1 } from '@fluentui/react-components'

const useStyles = makeStyles({
   card: {
      borderRadius: tokens.borderRadiusXLarge,
      padding: tokens.spacingHorizontalXXXL,
   },
   primary: {
      backgroundColor: tokens.colorBrandBackground,
      color: tokens.colorNeutralForegroundInverted,
   },
   beige: {
      backgroundColor: tokens.colorPaletteMarigoldBackground1,
   },
   headerCenter: {
      '& div': {
         justifyContent: 'center',
      },
   },
   center: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
   },
   stretch: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
   },
   title: {
      marginTop: 0,
      marginBottom: tokens.spacingVerticalL,
   },
})

type BasicCardProps = {
   children: JSX.Element | JSX.Element[] | string | string[]
   color: 'beige' | 'primary'
   title?: string
   info?: string
   className?: string
   description?: string
   alignItems?: 'center' | 'stretch'
   titleClassName?: string
}
export const BasicCard = ({ color, title, info, children, className, description, alignItems, titleClassName }: BasicCardProps): JSX.Element => {
   const classes = useStyles()
   return (
      <Card
         className={mergeClasses(
            classes.card,
            color === 'beige' ? classes.beige : classes.primary,
            alignItems === 'center' ? classes.center : alignItems === 'stretch' ? classes.stretch : '',
            className ?? ''
         )}
      >
         {title && (
            <CardHeader
               className={alignItems ? classes.center : ''}
               header={
                  <Subtitle1 className={mergeClasses(classes.title, titleClassName)} as={'h4'}>
                     {title}
                  </Subtitle1>
               }
               action={info ? <InfoLabel info={info} size="large" /> : null}
               description={description ? <Caption1>{description}</Caption1> : null}
            />
         )}
         {children}
      </Card>
   )
}
