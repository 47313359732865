import React from 'react'
import { makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components'

type GridProps = {
   children: JSX.Element | JSX.Element[]
   className?: string
}
const useStyles = makeStyles({
   grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(12, 1fr)',

      ...shorthands.gap(tokens.spacingVerticalL, tokens.spacingVerticalL),
      '@media screen and (min-width: 600px)': {
         ...shorthands.gap(tokens.spacingVerticalXXL, tokens.spacingHorizontalXXL),
      },
   },
})
export const Grid = ({ children, className }: GridProps) => {
   const classes = useStyles()
   return <article className={mergeClasses(classes.grid, className)}>{children}</article>
}
