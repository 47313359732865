import { Label, makeStyles, mergeClasses, Text, tokens } from '@fluentui/react-components'
import React from 'react'
const useStyles = makeStyles({
   mTM: {
      marginTop: tokens.spacingVerticalM,
   },
   value: {
      display: 'flex',
      flexDirection: 'column',
   },
})
type TextOutputProps = {
   label: string
   value: string
   className?: string
}
export const TextOutput = ({ label, value, className }: TextOutputProps) => {
   const classes = useStyles()

   return (
      <span className={mergeClasses(value, className ?? '')}>
         <Label weight="semibold">{label}</Label>
         <Text aria-label={label} className={classes.mTM} block size={300}>
            {value}
         </Text>
      </span>
   )
}
