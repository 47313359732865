import { makeStyles, tokens, MessageBar, MessageBarBody, MessageBarTitle, MessageBarActions, Button, Link } from '@fluentui/react-components'
import { t } from '../../i18n/i18n'
import React, { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Api, Law, StatusImport } from '../../api/schemas/schema'
import { useMsal } from '@azure/msal-react'
import { handleMessage } from '../../helpers/stateHelper'
import { DataContext } from '../../App'
import { Modal } from '../common/modal/Modal'
const useStyles = makeStyles({
   midRigth: { display: 'flex', justifyContent: 'flex-end', alignItems: 'center' },
   messageBar: { paddingTop: tokens.spacingVerticalSNudge, paddingBottom: tokens.spacingVerticalSNudge, marginBottom: tokens.spacingVerticalXS },
})
type LawChangeMessageBarProps = {
   setLaw: React.Dispatch<React.SetStateAction<Law>>
   law: Law
}
export const LawChangeMessageBar = ({ setLaw, law }: LawChangeMessageBarProps) => {
   const classes = useStyles()
   const dataContext = useContext(DataContext)
   const { accounts } = useMsal()
   const apiInstance = new Api({ baseUrl: process.env.REACT_APP_API_URL })
   const [open, setOpen] = useState<boolean>(false)
   const handleReset = async () => {
      try {
         law.statusImport = StatusImport.Empty
         law.changedBy = accounts[0].name
         law.paragraphs = []
         const updatedLaw = (await apiInstance.api.putApi(law)).data
         setLaw(updatedLaw)
      } catch (error) {
         handleMessage(dataContext.setRootState, 'error', t('SavedError'), t('CouldNotAcknowledgeChange') + law && law.sfsNr)
      }
   }

   return (
      <>
         <MessageBar className={classes.messageBar} intent="warning" layout="singleline">
            <MessageBarBody>
               <MessageBarTitle>{t('LawDemandIsBasedOnHasChanged')}</MessageBarTitle>
               <NavLink target="_blank" to={`/law-import/${law.id}`} style={{ textDecoration: 'none', color: 'black' }}>
                  <Link>{`${t('Law')} ${law.sfsNr}`}</Link>
               </NavLink>
            </MessageBarBody>
            <MessageBarActions>
               <Button onClick={() => setOpen(true)} size="medium">
                  {t('AcknowledgeChange')}
               </Button>
            </MessageBarActions>
         </MessageBar>
         <Modal
            open={open}
            title={t('ConfirmAcknowledgeChange')}
            children={t('ConfirmAcknowledgeChangeText') + law.sfsNr}
            actions={
               <>
                  <Button onClick={() => setOpen(false)}>{t('Cancel')}</Button>
                  <Button onClick={async () => await handleReset()} appearance="primary" size="medium">
                     {t('AcknowledgeChange')}
                  </Button>
               </>
            }
         />
      </>
   )
}
