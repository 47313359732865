import { Accordion, AccordionItem, AccordionHeader, Divider, AccordionPanel, Text, makeStyles, tokens } from '@fluentui/react-components'
import React from 'react'
import { Grid } from '../grid/Grid'
import { GridItem } from '../grid/GridItem'
const useStyles = makeStyles({
   minHeight: {
      '& > button': {
         minHeight: 'auto',
         paddingBottom: tokens.spacingVerticalXS,
      },
   },
   grid: {
      alignItems: 'end',
   },
})
type BasicAccordionProps = {
   headerText: string
   children: JSX.Element | JSX.Element[]
   className?: string
   actions?: JSX.Element | JSX.Element[]
   questionView?: boolean
   isOpen?: boolean
   onToggle?: () => void
   messageBar?: JSX.Element | JSX.Element[]
}
export const BasicAccordion = ({ headerText, children, className, actions, questionView, isOpen, onToggle, messageBar }: BasicAccordionProps): JSX.Element => {
   const classes = useStyles()
   return (
      <Accordion collapsible defaultOpenItems={['1']} openItems={questionView && (isOpen ? ['1'] : [])} onToggle={questionView && onToggle}>
         <AccordionItem value="1">
            <Grid className={classes.grid}>
               <GridItem size="5/12">
                  <AccordionHeader className={classes.minHeight} as="h2" expandIconPosition="start">
                     <Text weight="semibold">{headerText}</Text>
                  </AccordionHeader>
               </GridItem>
               <GridItem size="5/12">{messageBar ?? null}</GridItem>
               <GridItem size="2/12" className={className}>
                  {actions ?? null}
               </GridItem>
            </Grid>
            <Divider appearance="brand" />
            <AccordionPanel>{children}</AccordionPanel>
         </AccordionItem>
      </Accordion>
   )
}
