import React from 'react'
import {
   DataGrid,
   DataGridHeader,
   DataGridRow,
   DataGridHeaderCell,
   DataGridBody,
   DataGridCell,
   shorthands,
   tokens,
   makeStyles,
   Text,
   TableColumnDefinition,
   InfoLabel,
   mergeClasses,
   TableColumnSizingOptions,
   Spinner,
} from '@fluentui/react-components'
import { Title } from '../texts/Title'
import { Pagination } from './Pagination'

const useStyles = makeStyles({
   content: {
      ...shorthands.padding(tokens.spacingVerticalL),
   },
   list: {
      ...shorthands.margin(tokens.spacingVerticalXXL, 0),
   },
   spinner: {
      minHeight: '60vh',
      display: 'flex',
      justifyContent: 'center',
   },
})

type ListProps<T extends {}> = {
   columns: TableColumnDefinition<T>[]
   items: T[]
   numberOfLaws?: number
   onRowClick?: (item: T) => void
   title?: string
   info?: string
   resizableColumns?: boolean
   subText?: string
   className?: string
   pagination?: boolean
   numberOfPages?: number
   columnSizingOptions?: TableColumnSizingOptions
   loading?: boolean
   asCard?: boolean
   listClassName?: string
   pageSize?: number
}
export const List = <T extends {}>({
   columns,
   items,
   numberOfLaws,
   onRowClick,
   title,
   subText,
   info,
   className,
   pagination,
   numberOfPages,
   resizableColumns,
   columnSizingOptions,
   loading,
   asCard,
   listClassName,
   pageSize,
}: ListProps<T>) => {
   const classes = useStyles()

   return (
      <section className={mergeClasses(asCard ? classes.content : '', className ?? '')}>
         {title && <Title as="h4">{title}</Title>}
         {info && <InfoLabel info={info} size="large" />}

         {subText && (
            <>
               <br />
               <Text>{subText}</Text>
            </>
         )}
         <DataGrid
            items={items}
            columns={columns}
            focusMode="composite"
            className={mergeClasses(classes.list, listClassName ?? '')}
            resizableColumns={resizableColumns ?? false}
            columnSizingOptions={columnSizingOptions}
            style={{ minWidth: 'auto' }}
         >
            <DataGridHeader>
               <DataGridRow>{({ renderHeaderCell }) => <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>}</DataGridRow>
            </DataGridHeader>
            {loading ? (
               <div className={classes.spinner}>
                  <Spinner />
               </div>
            ) : (
               <DataGridBody<T>>
                  {({ item, rowId }) => (
                     <DataGridRow<T> key={rowId} onClick={() => onRowClick && onRowClick(item)}>
                        {({ renderCell }) => <DataGridCell>{renderCell(item)}</DataGridCell>}
                     </DataGridRow>
                  )}
               </DataGridBody>
            )}
            {pagination && <Pagination items={items} pageCount={numberOfPages} itemCount={numberOfLaws} pageSize={pageSize} />}
         </DataGrid>
      </section>
   )
}
