import React from 'react'
import { Api, Demand, DemandStatus } from '../api/schemas/schema'
import { IDataContext } from '../interfaces/IDataContext'
import { t } from '../i18n/i18n'
import { GetDemands } from '../api/demand'
import { handleMessage, setLoading } from './stateHelper'
const apiInstance = new Api({ baseUrl: process.env.REACT_APP_API_URL })

export const expandDemand = (dataContext: IDataContext, items: Demand[], rowKey: string): Demand[] => {
   try {
      const { selectedTab } = dataContext.state
      const newArray = [...items]
      const index = newArray.findIndex((x) => x.id === rowKey)
      const demandsbyparent = items.find((x) => x.id === rowKey).children

      newArray.splice(
         index + 1,
         0,
         ...demandsbyparent.filter((d) => (selectedTab === 'activeDemands' ? d.status !== DemandStatus.Archived : d.status === DemandStatus.Archived))
      )
      return newArray
   } catch (error) {
      dataContext.handleMessage(dataContext.setRootState, 'error', t('CouldntExpandRow'), '')
   }
}

export const toggleExpandAllDemands = (dataContext: IDataContext, expandAllDemands: boolean) => {
   const { demands, selectedTab } = dataContext.state
   let newExpandedRowsCollection: { [key: string]: boolean } = {}
   let allDemands: string[] = []

   if (expandAllDemands) {
      for (const demand of demands) {
         allDemands.push(demand.id)

         if (demand?.children?.length > 0) {
            const filteredChildren = demand.children
               .filter((child) => (selectedTab === 'activeDemands' ? child.status !== DemandStatus.Archived : child.status === DemandStatus.Archived))
               .sort((a: any, b: any) => a.order - b.order)

            for (const child of filteredChildren) {
               newExpandedRowsCollection[child.id] = true
               allDemands.push(child.id)
            }
         }
      }
   }
   dataContext.setRootState((prev) => ({
      ...prev,
      expandedDemands: expandAllDemands ? allDemands : [],
   }))
}

export const resetItems = (setItems: React.Dispatch<React.SetStateAction<Demand[]>>, items: Demand[], droppedItem: Demand, index: number) => {
   const newArray = [...items]
   newArray.splice(index, 1)
   if (droppedItem.level === 1) {
      const föreFörälder = newArray.find((x) => x.level === 1 && x.order === droppedItem.order - 1)
      const föreföräderIndex = föreFörälder ? newArray.findIndex((x) => x.id === föreFörälder.id) : -1
      let counter = 0
      items
         .filter((x) => x.parentId === föreFörälder?.id)
         // eslint-disable-next-line array-callback-return
         .map((x) => {
            counter += 1
            items.filter((c) => c.parentId === x.id).map((x) => (counter += 1))
         })
      newArray.splice(föreföräderIndex + counter + 1, 0, droppedItem)
   } else {
      const droppedItemParent = newArray.find((x) => x.id === droppedItem.parentId)
      const droppedItemParentIndex = newArray.findIndex((x) => x.id === droppedItemParent.id)
      let droppedItemParentChildren = 0
      items
         .filter((x) => x.parentId === droppedItemParent?.id && x.order < droppedItem.order)
         // eslint-disable-next-line array-callback-return
         .map((x) => {
            droppedItemParentChildren += 1
            items.filter((c) => c.parentId === x.id).map((x) => (droppedItemParentChildren += 1))
         })

      newArray.splice(droppedItemParentIndex + droppedItemParentChildren + 1, 0, droppedItem)
   }

   setItems(newArray)
}

export const handleDNDOrderSave = async (dataContext: IDataContext, demandsToUpdate: Demand[], modifiedBy: string) => {
   try {
      setLoading(dataContext.setRootState, true)
      demandsToUpdate = demandsToUpdate.map((x) => ({ ...x, modifiedBy }))
      const ok = await apiInstance.api.demandOrderBatchUpdate(demandsToUpdate)
      if (ok) {
         const demands = await GetDemands()
         dataContext.setRootState((prev) => ({ ...prev, demands }))
      } else {
         throw new Error()
      }
      handleMessage(dataContext.setRootState, 'success', t('DNDSuccess'), '')
   } catch (error) {
      handleMessage(dataContext.setRootState, 'error', t('SomethingWentWrong'), t('DNDFail'))
   } finally {
      setLoading(dataContext.setRootState, false)
   }
}

export const demandExpansion = (dataContext: IDataContext, demandId: string, setLoadingRows: React.Dispatch<React.SetStateAction<string[]>>) => {
   const { demands, expandedDemands } = dataContext.state
   setLoadingRows((prev) => [...prev, demandId])

   const isExpanded = expandedDemands.some((demand) => demand === demandId)
   const closeDemand = (id: string) => {
      dataContext.setRootState((prev) => ({
         ...prev,
         expandedDemands: prev.expandedDemands.filter((x) => x !== id),
      }))
   }

   if (isExpanded) {
      closeDemand(demandId)
      demands
         .find((demand) => demand.id === demandId)
         // eslint-disable-next-line array-callback-return
         ?.children?.map((child: Demand) => {
            closeDemand(child.id)
         })
   } else {
      dataContext.setRootState((prev) => ({
         ...prev,
         expandedDemands: [...prev.expandedDemands, demandId],
      }))
   }
   setLoadingRows((prev) => prev.filter((x) => x !== demandId))
}
