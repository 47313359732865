import { Button, Checkbox, MenuItem, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import React, { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { DataContext } from '../../App'
import { t } from '../../i18n/i18n'
import { GridItem } from '../../components/common/grid/GridItem'
import { Modal } from '../../components/common/modal/Modal'
import { useBoolean } from '@fluentui/react-hooks'
import { Grid } from '../../components/common/grid/Grid'
import { ControlledTextInput } from '../../components/common/inputs/TextInput'
import { ControlledDropDown } from '../../components/common/inputs/Dropdown'
import { enumValuesToOptions } from '../../helpers/enumHelper'
import { useForm } from 'react-hook-form'
import { AnswerOption, AnswerType, Api } from '../../api/schemas/schema'
import { Page } from '../Page'
import { BasicBreadcrumb } from '../../components/common/breadcrumb/BasicBreadcrumb'
import { setLoading } from '../../helpers/stateHelper'
import { createAnswerOption, deleteAnswerOption, getNewAnswerOption, updateAnswerOption } from '../../helpers/answerHelper'
import { useMsal } from '@azure/msal-react'
import { ContextualMenu } from '../../components/common/menu/Menu'
import { MoreHorizontal20Regular, Delete20Regular } from '@fluentui/react-icons'
import { Loader } from '../../components/common/spinner/Loader'

const useStyles = makeStyles({
   mTmB: { ...shorthands.margin(tokens.spacingVerticalXL, 'auto') },
   marginRight: {
      marginRight: tokens.spacingHorizontalXL,
   },
   rowReverse: { display: 'flex', flexDirection: 'row-reverse' },
   flexEnd: { display: 'flex', justifyContent: 'flex-end', alignItems: 'center' },
})
const AnswerOptionForm = () => {
   const { id } = useParams()
   const dataContext = useContext(DataContext)
   const { answerOptions, isLoading } = dataContext.state

   const [open, { setTrue: openModal, setFalse: closeModal }] = useBoolean(false)
   const classes = useStyles()
   const demandForm = useForm<AnswerOption>()
   const navigate = useNavigate()
   const { accounts } = useMsal()
   const {
      watch,
      reset,
      control,
      formState: { isDirty },
      handleSubmit,
      setValue,
   } = demandForm
   const apiInstance = new Api({ baseUrl: process.env.REACT_APP_API_URL })

   const onUndoClick = () => {
      if (id === 'new') {
         reset(getNewAnswerOption())
      } else {
         reset(answerOptions.find((x) => x.id === id))
      }
      closeModal()
   }
   const onSubmit = async (data: AnswerOption) => {
      setLoading(dataContext.setRootState, true)
      data.modifiedBy = accounts[0].name
      if (!watch('id')) {
         await createAnswerOption(data, apiInstance, dataContext, reset, navigate)
      } else {
         await updateAnswerOption(data, apiInstance, dataContext, reset)
      }
   }
   useEffect(() => {
      if (answerOptions && id !== watch('id')) {
         if (id === 'new' && !watch('id')) {
            reset(getNewAnswerOption())
         } else {
            reset(answerOptions.find((x) => x.id === id))
         }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [answerOptions, id, watch('id')])
   const isDisabled = watch('answerType') !== AnswerType.OwnAnswers

   return (
      <>
         <Page
            header={
               <GridItem size="12/12">
                  <BasicBreadcrumb
                     breadcrumbItems={[
                        { text: t('SystemSettings'), path: `/system-settings` },
                        { text: t('AnswerOptionList'), path: `/system-settings` },
                        {
                           text: watch('id') ? answerOptions.find((x) => x.id === watch('id')).text ?? watch('text') : t('New'),
                           path: window.location.pathname,
                        },
                     ]}
                  />
               </GridItem>
            }
         >
            {answerOptions === null ? (
               <Loader />
            ) : (
               <form onSubmit={handleSubmit(onSubmit)}>
                  <div className={classes.rowReverse}>
                     <ContextualMenu
                        menuTrigger={<Button appearance="transparent" icon={<MoreHorizontal20Regular />} aria-label="More options" />}
                        menuItems={
                           <MenuItem
                              disabled={isDisabled}
                              icon={<Delete20Regular />}
                              onClick={() => deleteAnswerOption(dataContext, watch(), navigate, apiInstance)}
                           >
                              {t('Delete')}
                           </MenuItem>
                        }
                     />
                  </div>
                  <Grid>
                     <GridItem size="8/12">
                        <ControlledTextInput control={control} name={'text'} label={t('AnswerOption')} required={true} disabled={isDisabled} />
                        <ControlledTextInput control={control} name={'infoLabel'} label={t('Info')} multiline={true} rows={5} />
                     </GridItem>
                     <GridItem size="4/12">
                        <ControlledTextInput control={control} name={'value'} label={t('Value')} required={true} type="number" />
                        <ControlledTextInput control={control} name={'order'} label={t('Order')} required={true} type="number" />
                        <ControlledDropDown
                           control={control}
                           name={'answerType'}
                           options={enumValuesToOptions(AnswerType)}
                           label={t('AnswerType')}
                           required={true}
                           disabled={true}
                        />
                        <Checkbox
                           label={t('ConnectDemandToAnswer')}
                           checked={watch('demandConnection') || false}
                           onChange={() => setValue('demandConnection', !watch('demandConnection'), { shouldDirty: true })}
                           disabled={true}
                           // Kommenterar ut nedan då detta ska sättas på paus. 17/9-2024
                           // disabled={isDisabled}
                        />
                     </GridItem>

                     <GridItem className={classes.flexEnd} size="12/12">
                        <Button className={classes.marginRight} disabled={!isDirty || isLoading} onClick={() => openModal()}>
                           {t('Undo')}
                        </Button>
                        <Button appearance="primary" type="submit" disabled={!isDirty || isLoading}>
                           {t('Save')}
                        </Button>
                     </GridItem>
                     <Modal
                        open={open}
                        title={t('UndoChanges')}
                        actions={
                           <>
                              <Button onClick={() => closeModal()}>{t('Cancel')}</Button>
                              <Button onClick={() => onUndoClick()} appearance="primary">
                                 {t('Continue')}
                              </Button>
                           </>
                        }
                        children={t('UndoAnswerOptionChanges')}
                     />
                  </Grid>
               </form>
            )}
         </Page>
      </>
   )
}

export default AnswerOptionForm
