// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IDataContext } from '../interfaces/IDataContext'
import { Api, Demand, DemandSearchResultDTO } from '../api/schemas/schema'
import { DropdownOption } from './enumHelper'
import { t } from '../i18n/i18n'
const apiInstance = new Api({ baseUrl: process.env.REACT_APP_API_URL })

export const searchDemands = async (
   searchValue: string,
   setSearchResult: React.Dispatch<React.SetStateAction<DemandSearchResultDTO[]>>,
   targetDemandLevel: number,
   filterOutDemand?: Demand
) => {
   let result = (await apiInstance.api.demandSearchList({ searchText: searchValue, demandLevel: targetDemandLevel })).data
   if (result.length > 0) {
      result = result.sort((a: any, b: any) => a.publicId - b.publicId)
      if (filterOutDemand) {
         result = result.filter((x) => x.id !== filterOutDemand.id)
      }
      setSearchResult(result)
   } else {
      setSearchResult([])
   }
}

export const getTargetDemandLevel = (dataContext: IDataContext): 2 | 1 => {
   const { selectedDemands } = dataContext.state
   const targetIsLvlOne = selectedDemands.some((demand) => demand.level === 2)
   return targetIsLvlOne ? 1 : 2
}

export const getDemandLeveLOptions = (selectedDemandLevel: number): DropdownOption[] => {
   const options = [
      { key: 2, text: 'D2' },
      { key: 3, text: 'D3' },
   ]
   if (selectedDemandLevel === 1) {
      options.splice(0, 0, { key: 1, text: 'D1' })
   }
   return options
}

export const getDemandSearchBoxLabel = (targetDemandLevel: number) => {
   return targetDemandLevel === 1 ? t('SearchForLevelOneDemands') : t('SearchForLevelTwoDemands')
}
