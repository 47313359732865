import { Field, Dropdown, Option } from '@fluentui/react-components'
import React, { useContext } from 'react'
import { Control, Controller } from 'react-hook-form'
import { DataContext } from '../../App'
import { QuestionFormValues } from '../../helpers/questionHelper'
import { t } from '../../i18n/i18n'

type ProcessDropdownProps = {
   control: Control<QuestionFormValues, any>
   questionId: string
   alternativeProcessIds: string[]
   disabled: boolean
   isRequired?: boolean
}
export const ProcessDropdown = ({ control, questionId, alternativeProcessIds, disabled, isRequired }: ProcessDropdownProps) => {
   const dataContext = useContext(DataContext)
   const { processes } = dataContext.state

   return (
      <Controller
         name={'selectedQuestion.process'}
         control={control}
         rules={{ required: isRequired }}
         render={({ field: { value, onChange }, fieldState: { invalid } }) => {
            return (
               <Field
                  label={t('Process')}
                  required={isRequired}
                  validationState={isRequired && invalid ? 'error' : null}
                  validationMessage={isRequired && invalid && t('SelectAnOption')}
               >
                  <Dropdown
                     value={value ? value.process?.text : ''}
                     onOptionSelect={(e, d) => {
                        if (value && value.process?.id === d.optionValue) {
                           onChange(null)
                        } else {
                           onChange({
                              questionId,
                              processGuid: d.optionValue,
                              process: processes.find((x: any) => x.id === d.optionValue),
                           })
                        }
                     }}
                     selectedOptions={[value && value.process?.id]}
                     style={{ minWidth: 'auto' }}
                     disabled={disabled}
                  >
                     {processes.map((x, i) => (
                        <Option key={i} value={x.id} disabled={alternativeProcessIds.includes(x.id)}>
                           {x.text}
                        </Option>
                     ))}
                  </Dropdown>
               </Field>
            )
         }}
      />
   )
}
