import { Table, TableHeader, TableRow, TableHeaderCell, TableBody, tokens, makeStyles } from '@fluentui/react-components'
import React, { useContext, useEffect, useState } from 'react'
import { questionColumns } from '../../helpers/columnHelper'
import { QuestionDTOPaginatedList, QuestionStatus } from '../../api/schemas/schema'
import { TableQuestionRow } from './TableQuestionRow'
import { Loader } from '../../components/common/spinner/Loader'
import { useSearchParams } from 'react-router-dom'
import { getQuestions } from '../../helpers/questionHelper'
import { Pagination } from '../../components/common/list/Pagination'
import { DataContext } from '../../App'

const useStyles = makeStyles({
   pagination: { width: '100%', position: 'sticky', bottom: 0 },
})

export const QuestionTable = () => {
   const classes = useStyles()
   const dataContext = useContext(DataContext)
   const [questions, setQuestions] = useState<QuestionDTOPaginatedList>(null)
   const [searchParams, setSearchParams] = useSearchParams()
   const pageSize = 20
   const page = parseInt(searchParams.get('page') || '1', pageSize)

   useEffect(() => {
      if (!searchParams.get('page')) setSearchParams({ page: '1' })
      else if (parseInt(searchParams.get('page')) > questions?.totalPages) setSearchParams({ page: questions?.totalPages.toString() })
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [page, searchParams])

   useEffect(() => {
      if ((typeof page === 'number' && !isNaN(page)) || page <= questions?.totalPages) {
         getQuestions(dataContext, page, setQuestions)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [page])

   return (
      <>
         <Table aria-label="Question table" as="table">
            <TableHeader as="thead">
               <TableRow as="tr">
                  {questionColumns().map((column) => (
                     <TableHeaderCell as="th" style={{ width: column.width, fontWeight: tokens.fontWeightBold }} key={column.columnId}>
                        {column.label}
                     </TableHeaderCell>
                  ))}
               </TableRow>
            </TableHeader>
            {!questions?.items ? (
               <Loader />
            ) : (
               <>
                  <TableBody as="tbody">
                     {questions?.items
                        .filter((q) => q.status !== QuestionStatus.Archived)
                        .map((question) => {
                           return <TableQuestionRow question={question} />
                        })}
                  </TableBody>
               </>
            )}
         </Table>
         {questions?.items && (
            <div className={classes.pagination}>
               <Pagination items={questions?.items} pageCount={questions?.totalPages} itemCount={questions?.totalItems} pageSize={pageSize} />
            </div>
         )}
      </>
   )
}
