import { Badge, tokens, mergeClasses, Switch, makeStyles, shorthands, ProgressBar, Checkbox } from '@fluentui/react-components'
import { NavLink, useNavigate } from 'react-router-dom'
import { DemandType, DemandSubType, DemandArea, DemandStatus, Demand } from '../../api/schemas/schema'
import StatusPill from '../../components/common/status/StatusPill'
import {
   addDemandFromList,
   demandSelectIsDisabled,
   getNewDemand,
   hasActiveChildDemands,
   hasArchivedChildDemands,
   hasQuestions,
   levelBadgeColor,
   onDemandSelect,
   statusBadgeColor,
} from '../../helpers/demandHelper'
import { enumValuesToTranslate } from '../../helpers/enumHelper'
import { ChevronDown20Filled, ChevronRight20Filled, AddCircle24Filled } from '@fluentui/react-icons'
import { useContext, useEffect, useState } from 'react'
import { t } from '../../i18n/i18n'
import { DataContext } from '../../App'
import { Text } from '../../components/common/texts/Text'
import { IExtendedColumn } from '../../interfaces/IColumn'
import { CustomQuestionRow } from '../Question/CustomQuestionRow'
import { useMsal } from '@azure/msal-react'
import { RTFOutput } from '../../components/common/outputs/RTFOutput'
import { saveScrollPosition, scrollToSavedPosition } from '../../helpers/scrollPositionHelper'
import { ContextualDemandMenu } from '../../components/demand/ContextualDemandMenu'

const useStyles = makeStyles({
   brand: { backgroundColor: tokens.colorBrandBackground },
   informative: { backgroundColor: tokens.colorNeutralBackground2Pressed, color: tokens.colorNeutralForeground1 },
   gold: { backgroundColor: tokens.colorPaletteGoldBackground2, color: tokens.colorNeutralForeground1 },
   flexColumn: {
      display: 'flex',
      flexDirection: 'column',
   },
   lvlMargin: { marginLeft: tokens.spacingHorizontalM },
   verticalAlignTopPT: { verticalAlign: 'top', paddingTop: '0.4em' },
   verticalAlignTopPT1: { verticalAlign: 'top', paddingTop: '0.6em' },
   verticalAlignTop: { verticalAlign: 'top' },
   cursorPointer: { cursor: 'pointer' },
   badge: { minWidth: '40px', width: 'fit-content' },
   bold: { fontWeight: tokens.fontWeightBold },
   centeredColumn: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: 'fit-content',
   },
   plusButtonContainer: {
      cursor: 'pointer',
      width: '100%',
      ...shorthands.border('none'),
      display: 'flex',
   },
   plusButton: {
      position: 'relative',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
   },
   marginTop: {
      marginTop: '8px',
   },
})
type ReorderRowProps = {
   items: Demand[]
   item: Demand
   parent: Demand
   expanded: boolean
   toggleRowExpansion: (demandId: string) => void
   columns: IExtendedColumn[]
   rowClass: string
   showButton: string
   setShowButton: React.Dispatch<React.SetStateAction<string>>
   expandAllDemands: boolean
   expanding: boolean
}

export const DemandRowContent = ({
   items,
   item,
   parent,
   expanded,
   toggleRowExpansion,
   rowClass,
   columns,
   showButton,
   setShowButton,
   expandAllDemands,
   expanding,
}: ReorderRowProps) => {
   const dataContext = useContext(DataContext)

   const { selectedTab, moveDemands, selectedDemands, expandedDemands } = dataContext.state
   const classes = useStyles()

   const navigate = useNavigate()
   const { accounts } = useMsal()
   const [questionExpanded, setQuestionExpanded] = useState<boolean>(false)

   useEffect(() => {
      if (expandAllDemands) {
         setQuestionExpanded(true)
      } else {
         setQuestionExpanded(false)
      }
   }, [expandAllDemands])

   useEffect(() => {
      scrollToSavedPosition()
   }, [])

   const handleAddDemand = async (demand: Demand) => {
      const newDemand = getNewDemand(parent, accounts[0].name)
      newDemand.order = demand.order + 1
      const siblingDemandsToRaiseOrder = parent.children.filter((x) => x.order > demand.order)
      const demandsToUpdate = siblingDemandsToRaiseOrder.map((updateDemand) => {
         return {
            ...updateDemand,
            order: updateDemand.order + 1,
         }
      })
      await addDemandFromList(newDemand, demandsToUpdate, parent, dataContext, navigate)
   }

   const getIcon = (item: Demand) => {
      switch (selectedTab) {
         case 'activeDemands':
            return hasActiveChildDemands(item) ? expanded || expanding ? <ChevronDown20Filled /> : <ChevronRight20Filled /> : null

         case 'archivedDemands':
            return hasArchivedChildDemands(item) ? expanded || expanding ? <ChevronDown20Filled /> : <ChevronRight20Filled /> : null

         default:
            break
      }
   }

   return (
      <>
         <div className={rowClass}>
            <span style={{ minWidth: columns[0].minWidth, width: columns[0].width }}>
               {moveDemands && selectedTab !== 'archivedDemands' && (
                  <Checkbox
                     checked={selectedDemands.some((x) => x.id === item.id)}
                     disabled={demandSelectIsDisabled(selectedDemands, item)}
                     onChange={(e) => {
                        const checked = e.target.checked
                        onDemandSelect(selectedDemands, item, expandedDemands, checked, dataContext, toggleRowExpansion)
                     }}
                  />
               )}
            </span>
            <span style={{ minWidth: columns[1].minWidth, width: columns[1].width }} className={classes.flexColumn}>
               <div>
                  <div className={classes.centeredColumn} style={{ marginLeft: `calc(${tokens.spacingHorizontalM} * ${item.level - 1})` }}>
                     <Badge
                        className={mergeClasses(levelBadgeColor(item.level, classes), classes.cursorPointer, classes.badge)}
                        icon={getIcon(item)}
                        onClick={() => {
                           const isParent = item.level !== 3
                           isParent && toggleRowExpansion(item.id)
                        }}
                     >
                        D{item.level}
                     </Badge>
                     <Switch
                        label={t('Question')}
                        disabled={!hasQuestions(item)}
                        checked={questionExpanded}
                        onChange={() => setQuestionExpanded((prevExpanded) => !prevExpanded)}
                        labelPosition="above"
                        className={classes.marginTop}
                     />
                  </div>
               </div>
            </span>

            <span style={{ minWidth: columns[2].minWidth, width: columns[2].width }}>
               <NavLink style={{ textDecoration: 'none', color: 'black' }} to={`/demands/${item.id}`} onClick={() => saveScrollPosition()}>
                  <Text>{item.publicId}</Text>
               </NavLink>
            </span>
            <span style={{ minWidth: columns[3].minWidth, width: columns[3].width }}>
               <NavLink style={{ textDecoration: 'none', color: 'black' }} to={`/demands/${item.id}`} onClick={() => saveScrollPosition()}>
                  {item.designation}
               </NavLink>
            </span>
            <span style={{ minWidth: columns[4].minWidth, width: columns[4].width }}>
               {
                  <NavLink style={{ textDecoration: 'none', color: 'black' }} to={`/demands/${item.id}`} onClick={() => saveScrollPosition()}>
                     <RTFOutput text={item.text.length > 300 ? item.text.substring(0, 300) + '...' : item.text} fontSize={300} noMargin />
                  </NavLink>
               }
            </span>
            <span style={{ minWidth: columns[5].minWidth, width: columns[5].width }}>{enumValuesToTranslate(DemandType, item.type)}</span>

            <span style={{ minWidth: columns[6].minWidth, width: columns[6].width }}>{enumValuesToTranslate(DemandSubType, item.subType)}</span>
            <span style={{ minWidth: columns[7].minWidth, width: columns[7].width }}>
               {item.source.length > 100 ? `${item.source.slice(0, 100)}...` : item.source}
            </span>
            <span style={{ minWidth: columns[8].minWidth, width: columns[8].width }}>{enumValuesToTranslate(DemandArea, item.area)}</span>

            <span style={{ minWidth: columns[9].minWidth, width: columns[9].width }}>
               <StatusPill color={statusBadgeColor(item.status)}>{enumValuesToTranslate(DemandStatus, item.status)}</StatusPill>
            </span>
            <span style={{ minWidth: columns[10].minWidth, width: columns[10].width }}>
               <ContextualDemandMenu parent={parent} />
            </span>
         </div>
         {expanding && item.level !== 3 && <ProgressBar thickness="large" />}
         {showButton === item.id && item.level > 1 && item.status !== DemandStatus.Archived ? (
            <div className={mergeClasses(classes.plusButtonContainer)} onClick={async () => await handleAddDemand(item)}>
               <span style={{ height: '1px', width: '100%' }} onMouseLeave={() => setShowButton('')}>
                  <AddCircle24Filled className={classes.plusButton} />
               </span>
            </div>
         ) : null}
         {questionExpanded ? (
            item.questions ? (
               item.questions.map((question) => <CustomQuestionRow columns={columns} rowClass={rowClass} question={question} demand={item} key={question.id} />)
            ) : (
               <ProgressBar thickness="large" />
            )
         ) : null}
      </>
   )
}
