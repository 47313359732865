import React, { useContext, useEffect, useState } from 'react'
import { Button, makeStyles, shorthands, tokens, Text, Spinner, CardHeader, MenuItem } from '@fluentui/react-components'
import { t } from '../../i18n/i18n'
import { Demand, DemandStatus, QuestionStatus } from '../../api/schemas/schema'
import { BasicAccordion } from '../common/accordion/BasicAccordion'
import { Add16Regular, Archive16Regular, Delete20Regular, MoreHorizontal20Regular } from '@fluentui/react-icons'
import { UseFormReturn } from 'react-hook-form'
import { DataContext } from '../../App'
import { Modal } from '../common/modal/Modal'
import { QuestionFormValues, addQuestion, deleteQuestion, saveQuestion } from '../../helpers/questionHelper'
import { useSearchParams } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import { QuestionCard } from './QuestionCard'
import { ToggleButton } from '../common/inputs/ToggleButton'
import { GetQuestionsByDemandId } from '../../api/question'
import { GridItem } from '../common/grid/GridItem'
import { ContextualMenu } from '../common/menu/Menu'

const useStyles = makeStyles({
   midRigth: { display: 'flex', justifyContent: 'flex-end', alignItems: 'center' },
   content: {
      display: 'flex',
      flexDirection: 'column',
      ...shorthands.margin(tokens.spacingVerticalL, 0),
   },
   header: {
      display: 'flex',
      justifyContent: 'space-between',
      ...shorthands.gap(tokens.spacingHorizontalS),
   },
   spinner: {
      marginTop: tokens.spacingHorizontalXXXL,
   },
})
type SelectedQuestionProps = {
   questionForm: UseFormReturn<QuestionFormValues, any, undefined>
   demand: Demand
   grandParentId: string
}

export const SelectedQuestion = ({ questionForm, demand, grandParentId }: SelectedQuestionProps) => {
   const classes = useStyles()
   const dataContext = useContext(DataContext)
   const [searchParams, setSearchParams] = useSearchParams()
   const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
   const [openModal, setOpenModal] = useState<'new' | 'badgeUndo' | 'undo'>(null)
   const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(true)
   const canBeActivated = demand?.status === DemandStatus.Archived
   const {
      reset,
      watch,
      handleSubmit,
      setValue,
      trigger,
      formState: { isDirty },
   } = questionForm
   const { accounts } = useMsal()
   useEffect(() => {
      if (demand && demand.id) {
         ;(async () => {
            const demandQuestions = await GetQuestionsByDemandId(demand.id)
            let order = Number(searchParams.get('question') ?? (demandQuestions.length > 0 && demandQuestions[0].order) ?? 1)

            if (demandQuestions.length > 0 && !demandQuestions.find((x) => Number(searchParams.get('question')) === x.order)) {
               searchParams.delete('question')
               setSearchParams(searchParams)
            }
            reset({ selectedQuestion: demandQuestions.find((x) => x.order === order), questions: demandQuestions })
         })()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [demand.id, demand.status])

   const onSubmit = async (data: QuestionFormValues) => {
      if (data.selectedQuestion.status === QuestionStatus.Definite) {
         trigger()
      }
      data.selectedQuestion.modifiedBy = accounts[0].name
      data.selectedQuestion.modifiedDate = new Date().toISOString()
      await saveQuestion(setIsSubmitting, dataContext, data, reset, demand, grandParentId)
   }
   const onUndoClick = () => {
      reset((prev) => ({ ...prev, selectedQuestion: watch('questions').find((x) => x.id === watch('selectedQuestion.id')) }))
      setOpenModal(null)
   }
   const isDisabled = watch('selectedQuestion.status') === QuestionStatus.Archived
   const questionButtonIsDisabled = demand.status === DemandStatus.Archived || watch('questions') == null

   return (
      <GridItem size="12/12">
         <BasicAccordion
            headerText={`${t('QuestionsForDemand')} - ${demand.designation}`}
            className={classes.midRigth}
            isOpen={isAccordionOpen}
            onToggle={() => setIsAccordionOpen((prev) => !prev)}
            questionView={true}
            actions={
               <Button
                  size="small"
                  icon={<Add16Regular />}
                  appearance="transparent"
                  onClick={async () => {
                     if (isDirty) {
                        setOpenModal('new')
                     } else {
                        addQuestion(dataContext, questionForm, setOpenModal, accounts[0].name, demand, grandParentId)
                     }
                     if (!isAccordionOpen) {
                        setIsAccordionOpen(true)
                     }
                  }}
                  disabled={questionButtonIsDisabled}
               >
                  {t('NewQuestion')}
               </Button>
            }
         >
            {!watch('questions') ? (
               <Spinner className={classes.spinner} label={t('LoadingQuestions')} />
            ) : (
               <form onSubmit={handleSubmit(onSubmit)} className={classes.content}>
                  <header className={classes.header}>
                     <div>
                        {watch('questions').map((x, i) => (
                           <ToggleButton
                              key={i}
                              selected={x.id === watch('selectedQuestion.id')}
                              onClick={() => {
                                 if (isDirty || watch('selectedQuestion.id') === null) {
                                    setOpenModal('badgeUndo')
                                 } else {
                                    reset((prev) => ({ ...prev, selectedQuestion: x }))
                                 }
                              }}
                              index={i}
                           >
                              {x.designation}
                           </ToggleButton>
                        ))}
                     </div>
                     <CardHeader
                        action={
                           <ContextualMenu
                              menuTrigger={<Button appearance="transparent" icon={<MoreHorizontal20Regular />} aria-label="More options" />}
                              menuItems={
                                 <>
                                    <MenuItem
                                       icon={<Archive16Regular />}
                                       onClick={() => {
                                          watch('selectedQuestion.status') === QuestionStatus.Archived
                                             ? setValue(`selectedQuestion.status`, QuestionStatus.Ongoing)
                                             : setValue(`selectedQuestion.status`, QuestionStatus.Archived)
                                          saveQuestion(setIsSubmitting, dataContext, watch(), reset, demand, grandParentId)
                                       }}
                                       disabled={canBeActivated}
                                    >
                                       {watch('selectedQuestion.status') === QuestionStatus.Archived ? t('Activate') : t('Archive')}
                                    </MenuItem>
                                    <MenuItem
                                       icon={<Delete20Regular />}
                                       onClick={() => deleteQuestion(setIsSubmitting, dataContext, watch(), reset, demand, grandParentId)}
                                    >
                                       {t('Delete')}
                                    </MenuItem>
                                 </>
                              }
                           />
                        }
                     />
                  </header>
                  {watch('selectedQuestion') ? (
                     <QuestionCard
                        questionForm={questionForm}
                        isSubmitting={isSubmitting}
                        setOpenModal={setOpenModal}
                        isDisabled={isDisabled}
                        parentDemand={demand}
                     />
                  ) : (
                     <Text>{watch('questions').length > 0 ? t('NoQuestionChosen') : t('NoQuestionsCreated')}</Text>
                  )}
               </form>
            )}

            {openModal !== null && (
               <Modal
                  open={openModal !== null}
                  title={
                     openModal === 'new'
                        ? `${t('Warning')}! ${t('LatestChangesWillBeUndone')}`
                        : openModal === 'badgeUndo'
                        ? t('SaveBeforeProceed')
                        : t('UndoChanges')
                  }
                  actions={
                     <>
                        <Button onClick={() => setOpenModal(null)}>{t('Cancel')}</Button>
                        {openModal !== 'badgeUndo' && (
                           <Button
                              onClick={() =>
                                 openModal === 'new'
                                    ? addQuestion(dataContext, questionForm, setOpenModal, accounts[0].name, demand, grandParentId)
                                    : onUndoClick()
                              }
                              appearance="primary"
                           >
                              {t('Continue')}
                           </Button>
                        )}
                     </>
                  }
                  children={
                     openModal === 'new'
                        ? t('AreYouSureLatestChangesWillBeUndone')
                        : openModal === 'badgeUndo'
                        ? t('FormHasUnsavedChanges')
                        : t('UndoQuestionChanges')
                  }
               />
            )}
         </BasicAccordion>
      </GridItem>
   )
}
