import { Badge, makeStyles, tokens } from '@fluentui/react-components'

type ToggleButtonProps = {
   onClick: () => void
   selected: boolean
   children: string
   index: number
}
const useStyles = makeStyles({
   pointer: { cursor: 'pointer' },
   pointerMarginLeft: {cursor: 'pointer', marginLeft: tokens.spacingHorizontalMNudge }
})
export const ToggleButton = ({ onClick, selected, children, index }: ToggleButtonProps) => {
   const classes = useStyles()
   return (
      <Badge
         role={selected ? 'header' : 'option'}
         className={`${index === 0 ? classes.pointer : classes.pointerMarginLeft}`}
         size="large"
         shape="rounded"
         appearance={selected ? 'filled' : 'outline'}
         color="brand"
         onClick={onClick}
      >
         {children}
      </Badge>
   )
}
