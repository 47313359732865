import { MessageBar, MessageBarBody, MessageBarTitle } from '@fluentui/react-components'
import React from 'react'
import { t } from '../../i18n/i18n'
export const NoLawsFoundMessageBar = () => {
   return (
      <MessageBar>
         <MessageBarBody>
            <MessageBarTitle>{t('NoResultByFilter')}</MessageBarTitle>
            {t('AdjustFilterToFindWhatYoureLookingFor')}
         </MessageBarBody>
      </MessageBar>
   )
}
