import React, { useContext, useEffect, useState } from 'react'
import { Page } from './Page'
import { Title } from '../components/common/texts/Title'
import { useMsal } from '@azure/msal-react'
import { Divider, makeStyles, tokens } from '@fluentui/react-components'
import { Grid } from '../components/common/grid/Grid'
import { GridItem } from '../components/common/grid/GridItem'
import { BasicCard } from '../components/common/card/BasicCard'
import { Activity, Api, LawImportStatus } from '../api/schemas/schema'
import { Loader } from '../components/common/spinner/Loader'
import { DashboardSidebar } from './Dashboard/DashboardSidebar'
import { List } from '../components/common/list/List'
import { getActivitiesColumns } from '../helpers/activitiesHelper'
import { DataContext } from '../App'
import { t } from '../i18n/i18n'
const useStyles = makeStyles({
   title: {
      fontSize: tokens.fontSizeHero800,
   },
   noMargin: {
      margin: 0,
   },
   alignBottom: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
   },
})
export type DashboardState = {
   numberOfQuestions: number
   numberOfCustomers: number
   latestActivities: Activity[]
   lawImportStats: LawImportStatus
}
export const Dashboard = () => {
   const dataContext = useContext(DataContext)
   const { accounts } = useMsal()
   const classes = useStyles()
   const [dashboardState, setDashboardState] = useState<DashboardState>()
   const apiInstance = new Api({ baseUrl: process.env.REACT_APP_API_URL })
   const [isLoading, setIsLoading] = useState<boolean>(true)
   useEffect(() => {
      ;(async () => {
         try {
            Promise.all([
               await apiInstance.api.questionCountList(),
               await apiInstance.api.lawImportStatusList(),
               await apiInstance.api.activityList({ take: 14 }),
               await apiInstance.api.clientOrganisationCountList(),
            ]).then(([numberOfQuestions, lawImportStatus, activities, numberOfCustomers]) => {
               setDashboardState((prev) => ({
                  ...prev,
                  numberOfQuestions: numberOfQuestions.data,
                  lawImportStats: lawImportStatus.data,
                  latestActivities: activities.data,
                  numberOfCustomers: numberOfCustomers.data,
               }))
            })
         } catch (error) {
            dataContext.handleMessage(dataContext.setRootState, 'error', t('SomethingWentWrong'), t('CouldntFetchDashboardData'))
         } finally {
            setIsLoading(false)
         }
      })()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   return (
      <Page header={<Title as="h2">{t('Dashboard')}</Title>}>
         {isLoading ? (
            <Loader />
         ) : (
            <Grid>
               <GridItem size="9/12" className={classes.alignBottom}>
                  <Title as="h3" className={classes.title}>
                     {t('Welcome')} {accounts[0].name.split(' ')[0]}!
                  </Title>
                  <BasicCard title={t('OngoingActivities')} color="beige" titleClassName={classes.noMargin}>
                     <Divider appearance="brand" />
                     <List
                        items={dashboardState?.latestActivities}
                        columns={getActivitiesColumns()}
                        listClassName={classes.noMargin}
                        resizableColumns
                        columnSizingOptions={{
                           lastModified: {
                              minWidth: 120,
                              defaultWidth: 150,
                           },
                           modifiedBy: {
                              defaultWidth: 220,
                              minWidth: 150,
                           },
                        }}
                     />
                  </BasicCard>
               </GridItem>
               <DashboardSidebar dashboardState={dashboardState} />
            </Grid>
         )}
      </Page>
   )
}
