import { makeStyles, tokens, mergeClasses, Label, Persona } from '@fluentui/react-components'
import React from 'react'
const useStyles = makeStyles({
   mTS: {
      marginTop: tokens.spacingVerticalS,
   },
   value: {
      display: 'flex',
      flexDirection: 'column',
   },
})
type PersonaOutputProps = {
   label: string
   value: string
   className?: string
}
export const PersonaOutput = ({ label, value, className }: PersonaOutputProps) => {
   const classes = useStyles()
   return (
      <span className={mergeClasses(classes.value, className ?? '')}>
         <Label weight="semibold">{label}</Label>

         <Persona textAlignment="center" name={value} className={classes.mTS} />
      </span>
   )
}

export default PersonaOutput
