import React, { useState, useContext } from 'react'
import { Modal } from '../common/modal/Modal'
import { Reorder } from 'framer-motion'
import { handleDNDOrderSave } from '../../helpers/dndHelper'
import { makeStyles, tokens, Text, Button, Card, mergeClasses } from '@fluentui/react-components'
import { ReOrder16Regular } from '@fluentui/react-icons'
import { DataContext } from '../../App'
import { t } from '../../i18n/i18n'
import { Demand } from '../../api/schemas/schema'
import { useMsal } from '@azure/msal-react'
const useStyles = makeStyles({
   group: {
      display: 'flex',
      flexDirection: 'column',
      gap: tokens.spacingVerticalS,
   },
   card: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
   },
   pointer: {
      cursor: 'grab',
   },
   grabbing: {
      cursor: 'grabbing',
   },
   textWrapper: {
      display: 'flex',
      gap: tokens.spacingHorizontalL,
      boxSizing: 'border-box',
   },
   publicId: {
      width: '30px',
   },
   designation: {
      width: '80px',
   },
})
type DemandDndModalProps = {
   setReorderDemands: React.Dispatch<React.SetStateAction<Demand[]>>
   setOpen: React.Dispatch<React.SetStateAction<boolean>>
   reorderDemands: Demand[]
   open: boolean
}
export const DemandDNDModal = ({ setReorderDemands, setOpen, reorderDemands, open }: DemandDndModalProps) => {
   const dataContext = useContext(DataContext)
   const { accounts } = useMsal()
   const classes = useStyles()
   const [dragging, setDragging] = useState<boolean>(false)
   const [hasChanged, setHasChanged] = useState<boolean>(false)
   return (
      <Modal
         open={open}
         title={t('ChangeOrder')}
         actions={
            <>
               <Button onClick={() => {setHasChanged(false); setOpen(false)}}>{t('Cancel')}</Button>
               <Button 
                  onClick={async () => {await handleDNDOrderSave(dataContext, reorderDemands, accounts[0].name); setHasChanged(false)}} 
                  appearance="primary" 
                  size="medium"
                  disabled={!hasChanged}
                  >
                  {t('ChangeOrder')}
               </Button>
            </>
         }
      >
         <Reorder.Group
            axis="y"
            values={reorderDemands}
            onReorder={(newOrder) => {
               setReorderDemands(newOrder.map((x, i) => ({ ...x, order: i + 1 })))
            }}
            as="hgroup"
            role="rowgroup"
            className={classes.group}
         >
            {reorderDemands.map((x) => (
               <Reorder.Item
                  onDragStart={() => {
                     setDragging(true)
                     setHasChanged(true)
                  }}
                  onDragEnd={async () => {
                     setDragging(false)
                  }}
                  value={x}
                  key={x.id}
                  as="div"
                  role="row"
               >
                  <Card size="small" className={mergeClasses(classes.card, dragging ? classes.grabbing : classes.pointer)}>
                     <div className={classes.textWrapper}>
                        <span className={classes.publicId}>
                           <Text weight="semibold">{x.publicId}</Text>
                        </span>
                        <span className={classes.designation}>
                           <Text>{x.designation}</Text>
                        </span>

                        <Text>{x.urlText}</Text>
                     </div>
                     <ReOrder16Regular />
                  </Card>
               </Reorder.Item>
            ))}
         </Reorder.Group>
      </Modal>
   )
}
