// import { UseFormSetValue } from 'react-hook-form'
// import { AnswerOption } from '../api/schemas/schema'
// import { QuestionFormValues } from './questionHelper'

import { UseFormReset, UseFormSetValue } from 'react-hook-form'
import { AnswerOption, AnswerOptionConnection, AnswerType, Api } from '../api/schemas/schema'
import { IDataContext } from '../interfaces/IDataContext'
import { AddItemToCollection, RemoveItemFromCollection, UpdateItemInCollection, setLoading } from './stateHelper'
import { NavigateFunction } from 'react-router-dom'
import { QuestionFormValues } from './questionHelper'
import { t } from '../i18n/i18n'
export const answerOptionConnections = (questionId: string, answerOptions: AnswerOption[]): AnswerOptionConnection[] => {
   const answerOptionConnections: AnswerOptionConnection[] = []
   for (let i = 0; i < answerOptions.length; i++) {
      answerOptionConnections.push({ answerOptionId: answerOptions[i].id, answerOption: answerOptions[i], questionId: questionId, demandId: null })
   }
   return answerOptionConnections
}

export const answerOptionConnection = (questionId: string, answerOptions: AnswerOption): AnswerOptionConnection => {
   const answerOptionConnection: AnswerOptionConnection = {
      answerOptionId: answerOptions.id,
      answerOption: answerOptions,
      questionId: questionId,
      demandId: null,
   }
   return answerOptionConnection
}

export const choosenAnswerOptions = (answerForm: string, setValue: UseFormSetValue<QuestionFormValues>, answerOptions: AnswerOption[], questionId: string) => {
   const selectedAnswerType = Number(answerForm)
   if (selectedAnswerType === AnswerType.OwnAnswers) {
      setValue('selectedQuestion.answerType', selectedAnswerType, { shouldDirty: true })
      setValue('selectedQuestion.answerOptions', [])
   } else {
      const answerOptionConnection = answerOptionConnections(
         questionId,
         answerOptions.filter((x) => x.answerType === selectedAnswerType)
      )
      setValue('selectedQuestion.answerType', selectedAnswerType, { shouldDirty: true })
      setValue('selectedQuestion.answerOptions', answerOptionConnection, { shouldDirty: true })
   }
}
export const createAnswerOption = async (
   item: AnswerOption,
   apiInstance: Api<unknown>,
   dataContext: IDataContext,
   reset: UseFormReset<AnswerOption>,
   navigate: NavigateFunction
) => {
   try {
      const answerOption: AnswerOption = (await apiInstance.api.answerOptionCreate(item)).data

      navigate(`/system-settings/answer-options/${answerOption.id}`)
      reset(answerOption)
      AddItemToCollection(dataContext.setRootState, dataContext.state.answerOptions, answerOption, 'answerOptions')
      dataContext.handleMessage(dataContext.setRootState, 'success', t('SavedSuccess'), '')
   } catch (error: any) {
      dataContext.handleMessage(dataContext.setRootState, 'error', t('SavedError'), error.message)
   } finally {
      setLoading(dataContext.setRootState, false)
   }
}

export const updateAnswerOption = async (item: AnswerOption, apiInstance: Api<unknown>, dataContext: IDataContext, reset: UseFormReset<AnswerOption>) => {
   try {
      const resp: any = await apiInstance.api.answerOptionUpdate(item)
      dataContext.handleMessage(dataContext.setRootState, 'success', t('SavedSuccess'), '')
      UpdateItemInCollection(dataContext.setRootState, dataContext.state.answerOptions, resp.data, 'answerOptions')
      reset(resp.data)
   } catch (error: any) {
      dataContext.handleMessage(dataContext.setRootState, 'error', t('SavedError'), error.message)
   } finally {
      setLoading(dataContext.setRootState, false)
   }
}
export const deleteAnswerOption = async (dataContext: IDataContext, item: AnswerOption, navigate: NavigateFunction, apiInstance: Api<unknown>) => {
   try {
      await apiInstance.api.answerOptionDelete(item.id)
      dataContext.handleMessage(dataContext.setRootState, 'success', t('ItemDeleted'), '')
      RemoveItemFromCollection(dataContext.setRootState, dataContext.state.answerOptions, item, 'answerOptions')
      navigate('/system-settings')
   } catch (error: any) {
      dataContext.handleMessage(dataContext.setRootState, 'error', t('DeleteError'), error.message)
   } finally {
      setLoading(dataContext.setRootState, false)
   }
}

export const getNewAnswerOption = (): AnswerOption => {
   return { answerType: 2 }
}
