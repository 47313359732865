import React, { useContext } from 'react'
import { t } from '../../i18n/i18n'
import { DemandSubType, Origin, DemandType, Law, DemandArea, LawStatus, StatusImport } from '../../api/schemas/schema'
import { enumValuesToOptions, getStatuses } from '../../helpers/enumHelper'
import { GridItem } from '../common/grid/GridItem'
import { BasicDropdown, ControlledDropDown } from '../common/inputs/Dropdown'
import { ControlledTextInput, TextInput } from '../common/inputs/TextInput'
import { LawInformation } from './LawInformation'
import { UseFormReturn } from 'react-hook-form'
import { LawFormValues } from '../../pages/SelectedLaw'
import { Link, makeStyles, shorthands, tokens, Text, Button } from '@fluentui/react-components'
import { clearLawStatusImport, updateSelectedLawStatus } from '../../helpers/lawHelper'
import { DataContext } from '../../App'
import { useMsal } from '@azure/msal-react'
import { OpenRegular } from '@fluentui/react-icons'
const useStyles = makeStyles({
   paddingL: {
      '@media screen and (min-width: 600px)': { ...shorthands.padding(tokens.spacingVerticalL, tokens.spacingHorizontalL) },
   },
   smallGap: {
      display: 'flex',
      flexDirection: 'column',
      ...shorthands.gap(tokens.spacingHorizontalM),
      '@media screen and (min-width: 600px)': {
         ...shorthands.gap(tokens.spacingHorizontalM),
      },
   },
   buttonContainer: { display: 'flex', alignItems: 'center', marginTop: tokens.spacingVerticalXXL, width: '100%', marginLeft: tokens.spacingHorizontalL },
   flexMargin: { display: 'flex', marginBottom: tokens.spacingVerticalXL },
   link: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      marginTop: tokens.spacingVerticalXXL,
      '& div': {
         display: 'flex',
         '& span': {
            fontSize: tokens.fontSizeBase500,
         },
      },
   },
   relevanceStatus: {
      width: '50%',
   },
})
type LawControlsProps = {
   demandForm: UseFormReturn<LawFormValues, any, undefined>
   selectedLaw: Law
   isDisabled: boolean
   setSelectedLaw: React.Dispatch<React.SetStateAction<Law>>
}
export const LawControls = ({ demandForm, selectedLaw, isDisabled, setSelectedLaw }: LawControlsProps) => {
   const { control, watch } = demandForm
   const { accounts } = useMsal()
   const dataContext = useContext(DataContext)
   const classes = useStyles()
   return (
      <>
         <GridItem size="4/12">
            <LawInformation law={selectedLaw} />
         </GridItem>
         <GridItem size="4/12">
            <div className={classes.flexMargin}>
               <BasicDropdown
                  disabled={
                     selectedLaw.status === LawStatus.Demand || selectedLaw.status === LawStatus.OngoingDemand || selectedLaw.status === LawStatus.Archived
                  }
                  selectedOptions={[getStatuses('Law').find((x) => Number(x.key) === selectedLaw.status)?.text]}
                  ariaLabel={t('Status')}
                  options={getStatuses('Law').filter((x) => !x.disabled)}
                  multiselect={false}
                  label={t('RelevanceStatus')}
                  onOptionSelect={async (e, d) => {
                     await updateSelectedLawStatus(dataContext.setRootState, d.optionValue, selectedLaw, accounts[0], setSelectedLaw)
                  }}
                  className={classes.relevanceStatus}
               />
               {selectedLaw.status === LawStatus.Demand ? (
                  <div className={classes.link}>
                     <Link href={`/demands/${watch('LawDemand').id}`}>
                        <div>
                           <Text>{watch('LawDemand').designation}</Text>
                           <OpenRegular fontSize={20} />
                        </div>
                     </Link>
                  </div>
               ) : selectedLaw.statusImport === StatusImport.Updated ? (
                  <div className={classes.buttonContainer}>
                     <Button
                        appearance="secondary"
                        onClick={() => {
                           clearLawStatusImport(selectedLaw, dataContext, setSelectedLaw)
                        }}
                     >
                        {t('KeepRelevanceStatus')}
                     </Button>
                  </div>
               ) : null}
            </div>
            <ControlledTextInput
               control={control}
               name={'LawDemand.text'}
               label={t('Demand')}
               multiline={true}
               disabled={isDisabled}
               required={true}
               rows={10}
            />
         </GridItem>
         <GridItem size="4/12" className={classes.smallGap}>
            <TextInput label={t('DemandLevel')} value={`D${watch('LawDemand.level')}`} disabled />
            <ControlledDropDown control={control} name={'LawDemand.type'} options={enumValuesToOptions(DemandType)} label={t('DemandType')} disabled={true} />
            <ControlledDropDown
               control={control}
               name={'LawDemand.area'}
               options={enumValuesToOptions(DemandArea)}
               label={t('DemandArea')}
               disabled={isDisabled}
            />
            <ControlledDropDown
               control={control}
               name={'LawDemand.subType'}
               options={enumValuesToOptions(DemandSubType)}
               label={t('SubType')}
               disabled={isDisabled}
            />
            <ControlledDropDown control={control} name={'LawDemand.origin'} options={enumValuesToOptions(Origin)} label={t('Origin')} disabled={true} />
         </GridItem>
      </>
   )
}
