import React, { useEffect, useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { Field, OptionGroup, Option, makeStyles, tokens, Dropdown } from '@fluentui/react-components'
import { SNI, SNIVersion } from '../../../api/schemas/schema'
import { t } from '../../../i18n/i18n'
import { QuestionFormValues } from '../../../helpers/questionHelper'
import { getSelectedGroups, getRelevantSnis, allClick, groupClick, getFilteredOutSnis, setSNIValue } from '../../../helpers/sniHelper'
const useStyles = makeStyles({
   optionGroupHeader: { fontWeight: tokens.fontWeightSemibold },
   // , '&:hover': { backgroundColor: tokens.colorNeutralBackground1Hover } },
})
type SniDropdownProps = {
   questionForm: UseFormReturn<QuestionFormValues>
   label: string
   items: SNI[]
   sniVersion: SNIVersion
   disabled?: boolean
   validationState?: 'error'
   isRequired?: boolean
}

export const SniDropdown = ({ questionForm, label, items, sniVersion, disabled, validationState, isRequired }: SniDropdownProps): JSX.Element => {
   const { control, setValue, watch } = questionForm

   const questionId = watch('selectedQuestion.id')
   const groups = items.map((x) => x.department).sort()
   const uniqueGroups = groups.filter((item, index, array) => array.indexOf(item) === index)

   const [selectedGroups, setSelectedGroups] = useState<string[]>(getSelectedGroups(watch('selectedQuestion.snIs') ?? [], items, uniqueGroups, sniVersion))
   const classes = useStyles()

   useEffect(() => {
      setSelectedGroups(getSelectedGroups(watch('selectedQuestion.snIs'), items, uniqueGroups, sniVersion))
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [questionId, uniqueGroups.length, watch('selectedQuestion.snIs')])
   return (
      <Controller
         name={'selectedQuestion.snIs'}
         control={control}
         rules={{ required: isRequired }}
         render={({ field: { value }, fieldState: { invalid } }) => {
            const relevantValues = getRelevantSnis(value, sniVersion)

            return (
               <Field
                  label={label}
                  validationState={validationState === 'error' || (isRequired && invalid) ? 'error' : null}
                  required={isRequired}
                  validationMessage={isRequired && invalid && t('SelectOneOrMoreOptions')}
               >
                  <Dropdown
                     multiselect
                     onOptionSelect={(e, data) => {
                        const valuesToKeepSelected = getFilteredOutSnis(value, sniVersion)
                        const selectedSnis = [
                           ...data.selectedOptions
                              .filter((option) => items.find((x) => x.id === option) !== undefined)
                              .map((option) => ({
                                 sniId: option,
                                 questionId: questionId,
                                 sni: items.find((x) => x.id === option),
                              })),
                        ]
                        setSNIValue(setValue, valuesToKeepSelected, selectedSnis)
                     }}
                     selectedOptions={relevantValues ? [...relevantValues.sort((a, b) => a.sni.code - b.sni.code).map((x) => x?.sniId), ...selectedGroups] : []}
                     style={{ minWidth: 'auto' }}
                     value={
                        relevantValues && relevantValues.length === items.length
                           ? 'Alla'
                           : relevantValues && relevantValues.length < items.length
                           ? relevantValues.map((x) => x.sni?.code).join(', ')
                           : ''
                     }
                     disabled={disabled}
                  >
                     <OptionGroup>
                        <Option
                           className={classes.optionGroupHeader}
                           onClick={(e) => {
                              e.stopPropagation()
                              allClick(setValue, watch('selectedQuestion.snIs'), items, sniVersion, questionId)
                           }}
                        >
                           {'Alla'}
                        </Option>
                     </OptionGroup>
                     {uniqueGroups.map((department) => (
                        <OptionGroup key={department}>
                           <Option
                              className={classes.optionGroupHeader}
                              onClick={() => groupClick(setValue, watch('selectedQuestion.snIs'), items, sniVersion, department, questionId)}
                           >
                              {department}
                           </Option>
                           {items
                              .filter((item) => item.department.charAt(0) === department.charAt(0))
                              .sort((a, b) => a.code - b.code)
                              .map((option) => (
                                 <Option onClick={(e) => e.stopPropagation()} key={option.id} value={option.id}>
                                    {option.code.toString().padStart(2, '0') + ' ' + option.comment}
                                 </Option>
                              ))}
                        </OptionGroup>
                     ))}
                  </Dropdown>
               </Field>
            )
         }}
      />
   )
}
