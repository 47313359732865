import React from 'react'
import { Button } from '@fluentui/react-components'
import { Demand } from '../../api/schemas/schema'
import { SelectedDemandModal } from '../../helpers/demandHelper'
import { Modal } from '../common/modal/Modal'
import { UseFormReturn } from 'react-hook-form'
import { DemandSearchBox } from './DemandSearchBox'
import { getDemandSearchBoxLabel } from '../../helpers/optionsHelper'
import { t } from '../../i18n/i18n'

type ParentDemandModalProps = {
   demandForm: UseFormReturn<Demand, any, undefined>
   setOpenModal: React.Dispatch<React.SetStateAction<SelectedDemandModal>>
   setTargetDemand: React.Dispatch<React.SetStateAction<Demand>>
   targetDemand: Demand
   openModal: SelectedDemandModal
   targetDemandLevel: number
}
export const ParentDemandModal = ({ demandForm, setOpenModal, setTargetDemand, targetDemand, openModal, targetDemandLevel }: ParentDemandModalProps) => {
   const { resetField, setValue } = demandForm

   const onParentChangeClick = () => {
      setValue('parentId', targetDemand.id)
      setOpenModal(null)
   }
   return (
      <Modal
         open={openModal === SelectedDemandModal.PARENT}
         title={t('ChooseParentDemand')}
         actions={
            <>
               <Button
                  onClick={() => {
                     resetField('level')
                     setOpenModal(null)
                     setTargetDemand(null)
                  }}
               >
                  {t('Cancel')}
               </Button>
               <Button appearance="primary" disabled={targetDemand === null} onClick={onParentChangeClick}>
                  {t('SetParentDemand')}
               </Button>
            </>
         }
         children={
            <DemandSearchBox
               targetDemandLevel={targetDemandLevel}
               targetDemand={targetDemand}
               placeholder={''}
               setTargetDemand={setTargetDemand}
               filterOutDemand={demandForm.watch()}
               label={getDemandSearchBoxLabel(targetDemandLevel)}
            />
         }
      />
   )
}
