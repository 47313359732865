import dayjs from 'dayjs'
import React from 'react'
import { Text } from '@fluentui/react-components'
import { Law } from '../../api/schemas/schema'
import { NavLink } from 'react-router-dom'
import { t } from '../../i18n/i18n'
type LawInformationProps = {
   law: Law
}
export const LawInformation = ({ law }: LawInformationProps) => {
   return (
      <article>
         <Text as="p" weight="semibold">
            {law?.title}
         </Text>
         <div>
            <Text as="p" weight="semibold">
               {`${t('SfsNo')}: `}
            </Text>
            <Text as="p">{law?.sfsNr}</Text>
         </div>
         <div>
            <Text as="p" weight="semibold">
               {`${t('Department')}: `}
            </Text>
            <Text as="p">{law?.department}</Text>
         </div>
         <div>
            <Text as="p" weight="semibold">
               {`${t('IssuedAt')}: `}
            </Text>
            <Text as="p">{law?.issued ? dayjs(law?.issued).format('YYYY-MM-DD') : ''}</Text>
         </div>
         <div>
            <Text as="p" weight="semibold">
               {`${t('ModificationRegister')}: `}
            </Text>
            <Text as="p">{law?.title}</Text>
         </div>
         <div>
            <Text as="p" weight="semibold">
               {`${t('ModifiedAt')}: `}
            </Text>
            <Text as="p">{law?.changed}</Text>
         </div>
         <div>
            <Text as="p" weight="semibold">
               {`${t('WebLink')}: `}
            </Text>
            <NavLink target="blank" to={`${law?.url}`}>
               {law?.sfsNr}
            </NavLink>
         </div>
      </article>
   )
}
