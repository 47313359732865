import { Configuration } from '@azure/msal-browser'

export const msalConfig: Configuration = {
   auth: {
      clientId: '739ad14b-969e-49af-9d6f-6f68a6edb67b',
      authority: 'https://login.microsoftonline.com/burdysolutions.onmicrosoft.com',
      redirectUri: process.env.REACT_APP_BASE_URL,
   },
   cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
   },
}
