import React, { useEffect, useState } from 'react'
import { Text, Link, mergeClasses, tokens, makeStyles } from '@fluentui/react-components'
import { NavLink } from 'react-router-dom'
import { Demand } from '../../api/schemas/schema'
import { t } from '../../i18n/i18n'

const useStyles = makeStyles({
   flex: { display: 'flex', gap: tokens.spacingHorizontalS },
   resetLink: {
      color: tokens.colorNeutralForeground1,
   },
   semibold: {
      fontWeight: tokens.fontWeightSemibold,
   },
   wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: tokens.spacingVerticalS,
   },
})
type SubDemandsProps = {
   originDemand: Demand
}
export const SubDemands = ({ originDemand }: SubDemandsProps) => {
   const [take, setTake] = useState<number>(10)
   const [subDemands, setSubDemands] = useState<Demand[]>(originDemand.children.slice(0, take))
   useEffect(() => {
      setSubDemands(originDemand.children.slice(0, take))
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [take, JSON.stringify(originDemand.children)])
   useEffect(() => {
      setTake(10)
   }, [originDemand.id])
   const classes = useStyles()
   if (originDemand.children.length === 0) return null
   return (
      <div className={classes.wrapper}>
         <Text size={300} block weight="semibold">
            {t('SubDemands')}
         </Text>
         <>
            {subDemands.map((x) => (
               <NavLink key={x.id} to={`/demands/${x.id}`} style={{ textDecoration: 'none', color: 'black' }}>
                  <Link className={classes.resetLink}>{x.designation}</Link>
               </NavLink>
            ))}
            {originDemand.children.length > 10 && originDemand.children.length !== subDemands.length ? (
               <span className={classes.flex}>
                  <Link
                     className={mergeClasses(classes.resetLink, classes.semibold)}
                     onClick={() => {
                        setTake((prev) => prev + 10)
                     }}
                  >
                     {t('ShowMore')}
                  </Link>
                  <Text>{`(${subDemands.length} ${t('of')} ${originDemand.children.length})`}</Text>
               </span>
            ) : null}
         </>
      </div>
   )
}
