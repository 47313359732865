import SiteConstants from '../assets/SiteConstants'
import { Law, LawPaginatedList } from './schemas/schema'
import axios from 'axios'

export const GetFilteredLaws = async (
   statuses: string[],
   statusImports: string[],
   columnSort: string,
   sortOrder: 'asc' | 'desc',
   page: number,
   pageSize: number
): Promise<LawPaginatedList> => {
   try {
      const statusQueryString = `&statuses=${statuses?.join('&statuses=')}`
      const statusImportsQueryString = `&statusImports=${statusImports?.join('&statusImports=')}`
      const columnSortQueryString = columnSort ? `&columnSort=${columnSort}&sortOrder=${sortOrder}` : ''
      let queryString = `page=${page}&pageSize=${pageSize}`

      if (statuses.length > 0) queryString = queryString + statusQueryString
      if (statusImports.length > 0) queryString = queryString + statusImportsQueryString
      if (columnSort !== '') queryString = queryString + columnSortQueryString

      const response = await axios.get(`/${SiteConstants.LAWS}?${queryString}`)

      const laws: LawPaginatedList = response.data
      return laws
   } catch (error) {
      throw error
   }
}

export const GetLawById = async (id: string): Promise<Law> => {
   try {
      const response = await axios.get(`/${SiteConstants.LAWS}/${id}`)
      const item: Law = response.data
      return item
   } catch (error) {
      throw error
   }
}

export const UpdateLaw = async (updatedLaw: Law): Promise<Law> => {
   try {
      const law = await axios.put(`/${SiteConstants.LAWS}`, updatedLaw, {
         headers: {
            'Content-Type': 'application/json',
         },
      })
      return law
   } catch (error) {
      throw error
   }
}

export const NumberOfLaws = async (statuses?: string[], statusImports?: string[]): Promise<number> => {
   try {
      let url = `/${SiteConstants.LAWS}/${SiteConstants.COUNT}?`

      if (statuses && statuses.length > 0) {
         const statusQueryString = statuses.join('&statuses=')
         url += `&statuses=${statusQueryString}`
      }
      if (statusImports && statusImports.length > 0) {
         const statusImportsQueryString = statusImports.join('&statusImports=')
         url += `&statusImports=${statusImportsQueryString}`
      }

      const response = await axios.get(url)
      const count = response.data
      return count
   } catch (error) {
      throw error
   }
}
