import { LawStatus, StatusImport } from '../api/schemas/schema'
import { TOptions } from 'i18next/typescript/options'
import { t } from '../i18n/i18n'
import { ILanguage } from '../interfaces/ILanguage'
export interface DropdownOption {
   key: number | string
   text: string
   disabled?: boolean
   value?: any
}

export const getStatuses = (view: 'Law' | 'LawFilter'): DropdownOption[] => {
   switch (view) {
      case 'Law':
         return [
            { key: LawStatus.Yes, text: t('Yes') },
            { key: LawStatus.No, text: t('No') },
            { key: LawStatus.Maybe, text: t('Maybe') },
            { key: LawStatus.OngoingDemand, text: t('OngoingDemand'), disabled: true },
            { key: LawStatus.Demand, text: t('Demand'), disabled: true },
            { key: LawStatus.Archived, text: t('Archived'), disabled: true },
            { key: LawStatus.Unhandled, text: t('Unhandled') },
         ]

      case 'LawFilter':
         return [
            { key: LawStatus.Unhandled, text: t('Unhandled') },
            { key: LawStatus.Yes, text: t('Yes') },
            { key: LawStatus.No, text: t('No') },
            { key: LawStatus.Maybe, text: t('Maybe') },
            { key: LawStatus.Demand, text: t('Demand') },
            { key: LawStatus.OngoingDemand, text: t('OngoingDemand') },
            { key: StatusImport.New, text: t('New') },
            { key: StatusImport.Updated, text: t('Updated') },
            { key: StatusImport.Revoked, text: t('Revoked') },
         ]
   }
}

export const enumValuesToOptions = <T>(enumObj: T): DropdownOption[] => {
   const options = []
   for (const [key, value] of Object.entries(enumObj)) {
      if (isNaN(Number(value))) {
         options.push({ key: Number(key), text: `${t(value as keyof ILanguage)}` })
      }
   }
   return options
}
export const enumValuesToTranslate = <T>(enumObj: T, key: number, tOptions?: TOptions): string => {
   const value = Object.entries(enumObj).find((x) => x[1] === key)?.[0]
   return value ? t(value as keyof ILanguage) : ''
}

export const valuationOptions = () => {
   let count = 10
   let options: DropdownOption[] = []
   for (let index = 0; index < count; index++) {
      const opt = {
         key: index + 1,
         text: (index + 1).toString(),
      }
      options.push(opt)
   }
   return options
}
