import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { Text, makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components'
import { Arrow } from './Arrow'
import { t } from '../../../i18n/i18n'

const useStyles = makeStyles({
   container: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: tokens.colorPaletteMarigoldBackground2,
      borderRadius: tokens.borderRadiusXLarge,
      boxShadow: tokens.shadow8,
      padding: `${tokens.spacingHorizontalXS} ${tokens.spacingVerticalM}`,
      position: 'sticky',
      bottom: tokens.spacingVerticalXXXL,
   },
   pagination: {
      listStyleType: 'none',
      display: 'flex',
      ...shorthands.paddingInline(0),
      justifyContent: 'center',
      ...shorthands.gap(tokens.spacingHorizontalMNudge),
      alignItems: 'center',
   },
   marginLeft: {
      marginLeft: tokens.spacingHorizontalXS,
   },
   marginRight: {
      marginRight: tokens.spacingHorizontalXS,
   },
   leftText: {
      marginRight: tokens.spacingHorizontalM,
   },
   rightText: {
      marginLeft: tokens.spacingHorizontalM,
   },
   text: {
      fontSize: tokens.fontSizeBase400,
      fontWeight: tokens.fontWeightSemibold,
      cursor: 'pointer',
   },
   link: {
      ...shorthands.padding(tokens.spacingHorizontalXS),
      cursor: 'pointer',
      fontSize: tokens.fontSizeBase400,
   },
   arrow: {
      fontSize: tokens.fontSizeBase400,
   },
})
type PaginationProps<T extends {}> = {
   items: T[]
   pageCount: number
   itemCount: number
   pageSize: number
}
export const Pagination = <T extends {}>({ items, pageCount, itemCount, pageSize }: PaginationProps<T>): JSX.Element => {
   const classes = useStyles()
   const [search, setSearch] = useSearchParams()
   const page = parseInt(search.get('page'))
   const numberOfPagesVisible = 16
   const count = (i: number) => {
      const prevCount = (i - 1) * pageSize + 1
      const lastPageCount = itemCount - (pageCount - 1) * pageSize
      const itemLength = i === pageCount ? lastPageCount - 1 : pageSize - 1

      if (i === 1) {
         return `${i} - ${page === 1 ? items.length : pageSize}`
      } else {
         return `${prevCount} - ${prevCount + itemLength}`
      }
   }

   const getPagination = (): JSX.Element[] => {
      const elements: JSX.Element[] = []
      for (let i = 1; i <= pageCount; i++) {
         if (i === 1 && i !== page) {
            elements.push(
               <li
                  key={`arrow-left-${i}`}
                  onClick={() => {
                     setSearch({ page: (page - 1).toString() })
                  }}
               >
                  <Arrow className={mergeClasses(classes.link, classes.arrow)} direction="left" />
                  <Text className={mergeClasses(classes.marginLeft, classes.text, classes.leftText)} weight={'semibold'}>
                     {t('Previous')}
                  </Text>
               </li>
            )
         }

         const isActive = page === i
         if (
            i === 1 ||
            i === pageCount ||
            (i <= numberOfPagesVisible && page <= Math.floor(numberOfPagesVisible / 2)) ||
            (page >= pageCount - Math.floor(numberOfPagesVisible / 2) && i > pageCount - numberOfPagesVisible) ||
            (page > Math.floor(numberOfPagesVisible / 2) && i < page + Math.floor(numberOfPagesVisible / 2) && i > page - Math.floor(numberOfPagesVisible / 2))
         ) {
            elements.push(
               <li
                  key={`page-${i}`}
                  onClick={() => {
                     setSearch({ page: i.toString() })
                  }}
               >
                  <Text className={classes.link} weight={isActive ? 'bold' : 'regular'}>
                     {count(i)}
                  </Text>
               </li>
            )
         }

         if (i === pageCount && i !== page) {
            elements.push(
               <li
                  key={`arrow-right-${i}`}
                  onClick={() => {
                     setSearch({ page: (page + 1).toString() })
                  }}
               >
                  <Text className={mergeClasses(classes.marginRight, classes.text, classes.rightText)}>{t('Next')}</Text>
                  <Arrow className={mergeClasses(classes.link, classes.arrow)} direction="right" />
               </li>
            )
         }
      }
      return elements
   }
   return (
      <div className={classes.container}>
         <ul className={classes.pagination}>{getPagination()}</ul>
      </div>
   )
}
