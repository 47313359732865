import * as React from 'react'
import { Field, makeStyles, SearchBox, tokens, Text } from '@fluentui/react-components'
import { useEffect, useState } from 'react'
import { Demand, DemandSearchResultDTO } from '../../api/schemas/schema'
import { searchDemands } from '../../helpers/optionsHelper'
import { DemandSearchResults } from './DemandSearchResults'
import { Search20Regular } from '@fluentui/react-icons'
import { t } from '../../i18n/i18n'

const useStyles = makeStyles({
   ul: {
      listStyleType: 'none',
      paddingLeft: 0,
      margin: 0,
   },
   li: {
      display: 'block',
      cursor: 'pointer',
      padding: tokens.spacingHorizontalM,
   },
   liHover: {
      ':hover': {
         backgroundColor: tokens.colorNeutralBackground2Hover,
      },
   },
   liContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
   },
   brand: { backgroundColor: tokens.colorBrandBackground, color: tokens.colorNeutralBackground1Pressed },
   gold: { backgroundColor: tokens.colorPaletteGoldBackground2, color: tokens.colorNeutralForeground1 },
   search: {
      width: '100%',
      maxWidth: '100%',
      '> span': {
         width: '28px',
      },
   },
   card: {
      maxWidth: '100%',
      maxHeight: '50vh',
      padding: 0,
      overflowY: 'auto',
   },
})
type SearchableComboboxProps = {
   setTargetDemand: React.Dispatch<React.SetStateAction<Demand>>
   targetDemand: Demand
   targetDemandLevel: number
   placeholder: string
   label: string
   filterOutDemand?: Demand
   required?: boolean
   hideSearchIcon?: boolean
   isDisabled?: boolean
}
export const DemandSearchBox = ({
   setTargetDemand,
   targetDemand,
   placeholder,
   targetDemandLevel,
   label,
   filterOutDemand,
   required,
   hideSearchIcon,
   isDisabled,
}: SearchableComboboxProps): JSX.Element => {
   const classes = useStyles()
   const [searchValue, setSearchValue] = useState<string>('')
   const [searchResult, setSearchResult] = useState<DemandSearchResultDTO[]>([])
   const [selected, setSelected] = useState(true)
   useEffect(() => {
      if (searchValue.trim() === '') {
         setSearchResult([])
      } else {
         searchDemands(searchValue.trim(), setSearchResult, targetDemandLevel, filterOutDemand)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [searchValue])
   useEffect(() => {
      if (targetDemand) {
         setSearchValue(targetDemand.designation)
      }
   }, [targetDemand])

   return (
      <Field required={required ?? false} label={label}>
         <SearchBox
            disabled={isDisabled ?? false}
            contentBefore={hideSearchIcon ? null : <Search20Regular />}
            placeholder={placeholder}
            value={searchValue}
            onChange={(e, data) => {
               setSearchValue(data.value)
               setSelected(false)
            }}
            className={classes.search}
         />
         {!selected ? (
            searchValue !== '' && searchResult.length < 1 ? (
               <li className={`${classes.li} `}>
                  <div className={classes.liContainer}>
                     <div>
                        <Text as="p" weight="bold">
                           {t('EmptySearchResult')}
                        </Text>
                     </div>
                  </div>
               </li>
            ) : searchResult.length > 0 ? (
               <DemandSearchResults setSelected={setSelected} searchResult={searchResult} setTargetDemand={setTargetDemand} targetDemand={targetDemand} />
            ) : null
         ) : null}
      </Field>
   )
}
