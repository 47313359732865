import React, { useContext, useEffect, useState } from 'react'
import { Page } from './Page'
import { DataContext } from '../App'
import { Button, Divider, InfoLabel, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { t } from '../i18n/i18n'
import { useParams } from 'react-router-dom'
import { Loading } from './Loading'
import { Grid } from '../components/common/grid/Grid'
import { GridItem } from '../components/common/grid/GridItem'
import { Title } from '../components/common/texts/Title'
import { GetParagraphsByLawId } from '../api/paragraph'
import { GetLawById } from '../api/law'
import { handleMessage, setLoading } from '../helpers/stateHelper'
import { Demand, DemandSubType, Law, LawStatus, Paragraph, StatusImport } from '../api/schemas/schema'
import { ParagraphComponent } from '../components/paragraph/Paragraph'
import { useFieldArray, useForm } from 'react-hook-form'
import { clearDemands, clearLawStatusImport, getNewSecurityDemand, saveLawAndParagraphDemands } from '../helpers/lawHelper'
import { LawControls } from '../components/law/LawControls'
import { useMsal } from '@azure/msal-react'
import { GetDemandById } from '../api/demand'
import SelectedLawHeader from '../components/law/SelectedLawHeader'
import { useBoolean } from '@fluentui/react-hooks'
import { Modal } from '../components/common/modal/Modal'
import { UserMessage } from '../components/common/messageBar/UserMessage'
import { LawFormButtons } from '../components/law/LawFormButtons'
const useStyles = makeStyles({
   paddingL: {
      ...shorthands.padding(tokens.spacingVerticalXL, tokens.spacingHorizontalXL),
   },
   buttonContainer: {
      '& Button': {
         marginRight: tokens.spacingHorizontalM,
      },
   },
   paragraphMsg: {
      marginTop: tokens.spacingVerticalMNudge,
      width: 'fit-content',
   },
})
export type LawFormValues = {
   LawDemand: Demand
   SelectedParagraphs: Array<Demand & { paragraphId: number; demandId: string }>
}
export const SelectedLaw = () => {
   const classes = useStyles()
   const dataContext = useContext(DataContext)
   const { isLoading } = dataContext.state
   const { setRootState } = dataContext
   const [selectedLaw, setSelectedLaw] = useState<Law>(null)
   const [paragraphs, setParagraphs] = useState<Paragraph[]>([])
   const { accounts } = useMsal()
   const demandForm = useForm<LawFormValues>({
      defaultValues: {
         LawDemand: getNewSecurityDemand(DemandSubType.Law),
         SelectedParagraphs: [],
      },
   })

   const { control, watch, reset } = demandForm
   const selectedParagraphsFieldArray = useFieldArray({
      control,
      name: 'SelectedParagraphs',
   })
   const isDisabled =
      selectedLaw && (selectedLaw.status === LawStatus.Demand || selectedLaw.status === LawStatus.No || selectedLaw.status === LawStatus.Archived)
   const { id } = useParams()
   const [clearDemandModalOpen, { setTrue: openClearDemandModal, setFalse: closeClearDemandModal }] = useBoolean(false)
   const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
   useEffect(() => {
      ;(async () => {
         setLoading(setRootState, true)
         try {
            const lawResult = await GetLawById(id)
            const paragraphsResult = await GetParagraphsByLawId(id)
            setParagraphs(paragraphsResult)

            const paragraphDemands: Array<Demand & { paragraphId: number; demandId: string }> = await Promise.all(
               paragraphsResult
                  .filter((x) => x.demandId)
                  .map(async (x) => ({
                     ...(await GetDemandById(x.demandId)),
                     paragraphId: x.id,
                     demandId: x.demandId,
                  }))
            )
            if (lawResult.demandId) {
               const lawDemand = await GetDemandById(lawResult.demandId)
               reset({ LawDemand: lawDemand, SelectedParagraphs: paragraphDemands })
            } else {
               reset((prev) => ({
                  ...prev,
                  SelectedParagraphs: paragraphDemands,
               }))
            }
            setSelectedLaw(lawResult)
         } catch (error) {
            handleMessage(dataContext.setRootState, 'error', t('FetchFailed'), t('CouldNotFetchLaw'))
         } finally {
            setLoading(setRootState, false)
         }
      })()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [id])
   const onSubmit = (law: Law) => {
      setIsSubmitting(true)
      saveLawAndParagraphDemands(dataContext, reset, setSelectedLaw, setParagraphs, watch(), paragraphs, law, accounts[0], setIsSubmitting)
   }
   const onDemandReview = () => {
      clearLawStatusImport(selectedLaw, dataContext, setSelectedLaw, true)
   }

   return (
      <>
         {isLoading || !selectedLaw ? (
            <Loading />
         ) : (
            <Page header={<SelectedLawHeader selectedLaw={selectedLaw} />}>
               <Grid className={classes.paddingL}>
                  <GridItem size="8/12">
                     {selectedLaw.status === LawStatus.No && <UserMessage title={'LawWithStatusNoCantBeHandled'} message={'ChangeStatusToHandleLaw'} />}
                     {selectedLaw.status === LawStatus.Demand && <UserMessage title={'LawHasBeenSentToDemandList'} message={'LawCantBeEdited'} />}
                     {selectedLaw.statusImport === StatusImport.Updated && selectedLaw.status !== LawStatus.Demand && (
                        <UserMessage title={'LawHasBeenUpdatedSinceLastUpdate'} message={'ChangeStatusToUpdateImportStatus'} type={'warning'} />
                     )}
                     {selectedLaw.statusImport === StatusImport.Updated && selectedLaw.status === LawStatus.Demand && (
                        <UserMessage title={'LawIsUpdated'} message={'PleaseReviewDemands'} type={'warning'} reviewDemands onDemandReview={onDemandReview} />
                     )}
                  </GridItem>
                  <GridItem size="4/12">
                     <LawFormButtons
                        selectedLaw={selectedLaw}
                        demandForm={demandForm}
                        isSubmitting={isSubmitting}
                        isDisabled={isDisabled}
                        onSubmit={onSubmit}
                        openClearDemandModal={openClearDemandModal}
                     />
                  </GridItem>
                  <LawControls demandForm={demandForm} selectedLaw={selectedLaw} isDisabled={isDisabled} setSelectedLaw={setSelectedLaw} />
                  <GridItem size="12/12">
                     <div>
                        <Title as="h4">{`${t('Content')}: `}</Title>
                        <InfoLabel info={t('ChooseParagraphsInfo')} />
                     </div>
                     <Divider appearance="brand" />
                     <>
                        {paragraphs.length > 0 ? (
                           paragraphs.map((p, i) => (
                              <ParagraphComponent
                                 key={i}
                                 item={p}
                                 control={control}
                                 selected={watch('SelectedParagraphs').some((f) => f.paragraphId === p.id)}
                                 selectedParagraphsFieldArray={selectedParagraphsFieldArray}
                                 isDisabled={isDisabled}
                              />
                           ))
                        ) : (
                           <div className={classes.paragraphMsg}>
                              <UserMessage title={'LawDoesNotContainParagraphs'} message={'ParagraphsCouldNotBeFetched'} />
                           </div>
                        )}
                     </>
                  </GridItem>
               </Grid>
               <Modal
                  open={clearDemandModalOpen}
                  title={t('ClearDemand')}
                  actions={
                     <>
                        <Button onClick={closeClearDemandModal}>{t('Cancel')}</Button>
                        <Button
                           onClick={() =>
                              clearDemands(dataContext, reset, setSelectedLaw, setParagraphs, paragraphs, selectedLaw, accounts[0], closeClearDemandModal)
                           }
                           appearance="primary"
                        >
                           {t('Clear')}
                        </Button>
                     </>
                  }
                  children={t('ClearDemandText')}
               />
            </Page>
         )}
      </>
   )
}
