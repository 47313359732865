import { Button, makeStyles, tokens } from '@fluentui/react-components'
import { ArrowClockwiseRegular, SaveRegular, BookAddRegular } from '@fluentui/react-icons'
import { UseFormReturn } from 'react-hook-form'
import { LawFormValues } from '../../pages/SelectedLaw'
import { Law, LawStatus } from '../../api/schemas/schema'
import { t } from '../../i18n/i18n'
const useStyles = makeStyles({
   buttonContainer: {
      '& Button': {
         marginRight: tokens.spacingHorizontalM,
      },
   },
})
type LawFormButtonProps = {
   selectedLaw: Law
   demandForm: UseFormReturn<LawFormValues, any, undefined>
   isSubmitting: boolean
   isDisabled: boolean
   onSubmit: (law: Law) => void
   openClearDemandModal: () => void
}
export const LawFormButtons = ({ selectedLaw, demandForm, isSubmitting, isDisabled, onSubmit, openClearDemandModal }: LawFormButtonProps) => {
   const classes = useStyles()
   const {
      trigger,
      watch,
      formState: { isDirty, errors },
   } = demandForm
   return (
      <div className={classes.buttonContainer}>
         <Button
            disabled={
               isDisabled ||
               isSubmitting ||
               (watch('LawDemand.text')?.length === 0 && watch('SelectedParagraphs').length === 0 && selectedLaw.status !== LawStatus.OngoingDemand)
            }
            appearance="outline"
            onClick={openClearDemandModal}
            icon={<ArrowClockwiseRegular />}
         >
            {t('Clear')}
         </Button>
         <Button
            disabled={isDisabled || !isDirty || isSubmitting}
            appearance="secondary"
            onClick={() => {
               onSubmit({ ...selectedLaw, status: LawStatus.OngoingDemand })
            }}
            icon={<SaveRegular />}
         >
            {t('Save')}
         </Button>
         <Button
            type="submit"
            appearance="secondary"
            disabled={isDisabled || watch('LawDemand.text')?.length === 0 || isSubmitting}
            onClick={() => {
               trigger()
               if (Object.keys(errors).length === 0 && watch('LawDemand.text')?.length > 0) {
                  onSubmit({ ...selectedLaw, status: LawStatus.Demand })
               }
            }}
            icon={<BookAddRegular />}
         >
            {t('SendToDemandList')}
         </Button>
      </div>
   )
}
